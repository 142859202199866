/* * 업무구분: 신탁>영업>변경/해지/이체>실시간입금>실시간입금처리 * 화 면 명: MSPTS211M * 화면설명: 실시간입금처리[TFIO49000.xfdl:8282] * 작 성 일: 2023.03.16 * 작 성 자: 박은희 * F10600182
selectTFIO49000G [NEXT 사랑On 신탁]개인(신용)정보 등의 처리 및 출금이체 동의서  
* 수정일 : 2024-07-16 정수진  사유 : ASR240700667 / [사랑on신탁] 서식_일괄체크리스트변경
* 수정일 : 2024-08-06 정수진  사유 : ASR240701107 / 상품설명서 갱신에 따른 태블릿 전자서식 수정
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="실시간입금관리" :topButton="true">
    <!-- header start -->
    <ts-header ref="tsHeader" :propObj="pHeaderObj"></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <mo-tab-box default-idx="2" class="fts-tab">
          <mo-tab-label idx="1" @click="fn_goPage()">실시간계좌등록/해지</mo-tab-label>
          <mo-tab-label idx="2"><router-link to="#">실시간입금처리</router-link></mo-tab-label>
        </mo-tab-box>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w100p type03">
                <div class="wrap-input row">
                  <label class="w50"> 계좌번호 </label>
                  <!-- 종합계좌 번호 key-in  처리시 selectTFIO40000 호출하여 박핵심  1101547000 1101547001-->
                  <!-- param (tacno,bnkbSeq ,accSeq 받아 처리하고 (ds_tab2Search)) -->
                  <!-- ds_detail=tfaacb ds_detail04=tftcob ds_detail06=tfcprb -->
                  <!-- ds_depositCls add 처리해야함. 해당 함수 필요 -->
                  <mo-decimal-field class="w130" numeric mask="#######-###" v-model="div_tacno.tacno" maxlength="11" @input="fn_SearchValid()" /><!-- 종합계좌번호 @change="fn_accSeqitemchanged" -->
                  <mo-dropdown :items="div_tacno.accSeqs" v-model="div_tacno.accSeq" class="w200" placeholder="선택하세요" />
                  <mo-button class="btn-pop-download" @click="fn_OpenMSPTS206P"> </mo-button>
                  <mo-text-field class="w130" v-model="div_tacno.bnkAccDepoNm" disabled /><!-- 예금주-->
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p type03">
                <div class="wrap-input row">
                  <label class="w50"> 구분 </label>
                  <mo-dropdown :items="div_tacno.depositCls" v-model="div_tacno.deposit" class="w200" placeholder="선택하세요" />
                </div>
              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button @click="fn_init"> 초기화 </mo-button>
                  <mo-button primary @click="fn_searchList"> 조회 </mo-button>
                  <!-- <mo-button @click="fn_checkProcess()" primary> 전자문서 조회 </mo-button> -->

                  <!--<mo-button @click="fn_test"> 계좌번호 뒷단 호출 테스트 </mo-button>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">실시간 출금계좌 선택</h2>
        </div>
        <div class="wrap-table h-scroll-200">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w40"></th>
                <th>예금주명</th>
                <th>은행명</th>
                <th>은행계좌번호</th>
                <th>실명번호</th>
                <th>등록채널</th>
                <th>처리일자</th>
                <th>처리자</th>
                <th>유선구분</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in ds_tab1List" :key="idx" :class="{ checked: String(valueRadio) === String(idx + 1) }">
                <td>
                  <mo-radio :disabled="isGridClickDisabled === false" @click="fn_rowSelected(idx)" v-model="valueRadio" :value="String(idx + 1)"></mo-radio>
                </td>
                <td>{{ row.bnkAccDepoNm }}</td>
                <td>{{ row.bknm }}</td>
                <td>{{ stringBnkAcnoFormat(row.bnkAcno) }}</td>
                <td>{{ stringrDeporRrnoFormat(row.deporRrno) }}</td>
                <td>{{ row.rltiAccRegBusnTcNm }}</td>
                <td>{{ dateYyMmDdFormat(row.procDate) }}</td>
                <td>{{ row.procEnnm }}</td>
                <td>{{ row.telTypNm }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">계좌정보</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col1419">
            <tbody>
              <tr class="wrap-input">
                <th>
                  <span>출금은행</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" disabled v-model="bknm" />
                    <!-- edt_payBank , bknm-->
                  </div>
                </td>
                <th>
                  <span>출금계좌번호</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" disabled v-model="bnkAcno" />
                    <!-- edt_payBankAcno01  bnkAcno-->
                  </div>
                </td>
                <th class="bl-ddd">
                  <span>예금주명</span>
                </th>
                <td>
                  <div class="wrap-input row"><mo-text-field class="input-long" disabled v-model="bnkAccDepoNm" /><!-- edt_payBankAcnm  bnkAccDepoNm--></div>
                </td>
              </tr>
              <tr class="wrap-input">
                <th>
                  <span>입금계좌주명</span>
                </th>
                <td>
                  <div class="wrap-input row"><mo-text-field class="input-long" disabled v-model="bnkAccDepoNm" /><!-- edt_iamtAcnm bnkAccDepoNm--></div>
                </td>
                <th>
                  <span>출금동의</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-button @click="fn_confirm" :disabled="isConsentConfirmBtnDisabled"> 확인 </mo-button
                    ><!-- btn_withDrawAgree -->
                    <mo-button primary @click="fn_withDrawAgreeCanCel" v-if="isConsentCancelBtnDisabled"> 출금계좌선택해제 </mo-button
                    ><!-- btn_withDrawAgreeCanCel -->

                    <!-- :disabled="isRegBtnDisabled" -->
                  </div>
                </td>
                <td colspan="2" class="bl-ddd"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">입금정보</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col1419">
            <tbody>
              <tr class="wrap-input">
                <th>
                  <span>알림톡발송여부</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="rdoSmsYn" v-model="rdo_smsYn" :disabled="isRdoDisabled" />
                    <!-- rdo_smsYn -->
                  </div>
                </td>
                <th>
                  <span>별납수수료</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field mask="number" class="input-long" disabled v-model="edt_payno" />
                    <!--edt_payno -->
                  </div>
                </td>
                <th class="bl-ddd">
                  <span>이체요청금액</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field mask="number" class="input-long" :disabled="isPaymAmt" v-model="edt_paymAmt" numeric />
                    <!--edt_paymAmt -->
                  </div>
                </td>
              </tr>
              <tr class="wrap-input">
                <th>
                  <span>부가가치세</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field mask="number" class="input-long" disabled v-model="edt_addFee" />
                    <!--edt_addFee -->
                  </div>
                </td>
                <th>
                  <span class="emphasis">비밀번호</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <m-trans-key-input
                      v-if="isMobile"
                      v-model="edt_pwd"
                      :disabled="isPwd"
                      class="login-password"
                      type="numberMax4"
                      maxlength="4"
                      placeholder="비밀번호"
                      start="0"
                      end="-1"
                      dialog="Y"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt"
                    >
                    </m-trans-key-input>

                    <mo-text-field
                      v-else
                      v-model="edt_pwd"
                      :disabled="isPwd"
                      underline
                      maxlength="4"
                      placeholder="비밀번호"
                      type="password"
                      @keyup="fn_PwdValid($event)"
                      password
                      class="login-password"
                    />
                    <!-- 비밀번호 -->

                    <mo-button :disabled="isDepoReqBtnDisabled" @click="fn_depoReq"> 의뢰 </mo-button>
                    <!-- btn_depoReq -->
                  </div>
                </td>
                <td colspan="2" class="bl-ddd"></td>
              </tr>
              <!-- 승인금액, 승인번호 브리찌와이엔 recYn(파생녹취대상여부) -->
            </tbody>
          </table>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <ts-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></ts-alert-popup>

    <msp-ts-206p ref="popup206" :popup206Obj="pPopup206Obj" @ts-alert-popup="fn_AlertPopup" @ts-popup206-callback="fn_Popup206Back"></msp-ts-206p>

    <msp-ts-203p ref="popup203" :popup203Obj="pPopup203Obj" @ts-alert-popup="fn_AlertPopup" @ts-popup203-callback="fn_Popup203Back"></msp-ts-203p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import MSPTS206P from '@/ui/ts/MSPTS206P' //계좌번호 조회
import MSPTS203P from '@/ui/ts/MSPTS203P' //RT입금처리팝업_P
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSHeader from '~/src/ui/ts/comm/TSHeader' // header 영역 (공통)
import TSInfoUtil from '~/src/ui/ts/comm/TSInfoUtil' // Report (공통)

// 종합계좌번호 검색 param
const defaultSearch = {
  tacno: '', // 종합계조번호
  bnkbSeq: '', // 일련번호
  accSeq: '', // AccSeq
  bnkAccDepoNm: '', // 예금주
  trstTypC: '',
  cnm: '',
  msUseYn: 'Y',
  msData: '',
  accStatC: '',
  deposit: '',

  accSeqs: [], // 계좌 상품코드 콤보
  depositCls: [], // 브릿지 OR 신탁입금/입고
  feeDetail: [],

  curC: '',
  contDate: '',
  mtrtDate: '',
  brigAccYn: '',
  gdC: '',
  trstAmt: '',
  gdTypDtlC: '',
  mngBrcd: '',
  csId: '',
  fundC: '',
  rcno: '',
  rcnoKindTc: '',
  soptTc: '',
  gdNm: '',

  edt_brigAccYn01: ''
}

const defaultAccSeq = {
  c: '002', // 코드
  cNm: '홀라이프금전채권' // 명
}

const defaultDepositCls = {
  c: 'NEW', // 코드
  cNm: '신탁입금/입고' // 명
}

const defaultTab1List = {
  bnkAccDepoNm: '',
  bnkAcno: '',
  bnkNm: '',
  rcno: '',
  custAccnRltnCdNm: '',
  accnTrtPathCdNm: '',
  bkcd: '',
  regCls: '',
  regClsNm: '',
  deporRrno: '',
  acnoId: '',
  procDate: '',
  procEnnm: '',
  telTyp: '',
  procCls: '',
  rltiAccSeq: '',
  telTypNm: '',
  procObjAcno: ''
}

const defaultWithDrawAgreeDetail = {
  firstWdcsYn: '', // 코드
  wdcsYn: '', // 명
  payno: '', // 명
  wdcsTeleYn: '' // 명
}

// 확인버튼 클릭시
const defaultConfirm = {
  tacno: '', // 코드
  bnkbSeq: '', // 명
  accSeq: '', // 명
  pwd: '', // 명
  procCls: '', // 명
  feeCls: '' // 명
}

const defaultDetail = {
  tacno: '',
  bnkbSeq: '',
  accSeq: '',
  lastChngDt: '',
  lastChngMnTmno: '',
  lastChngMnUsid: '',
  trstTypC: '',
  csId: '',
  chngSeq: '',
  accStatC: '',
  contDate: '',
  mtrtDate: '',
  tmtlDate: '',
  trstAmt: '',
  balPrin: '',
  mngBrcd: '',
  invtMnEmno: '',
  pldgSetAmt: '',
  pldgSetCnt: '',
  entLmtAmt: '',
  padNmts: '',
  trstOtxtGiveDate: '',
  tmtlRsn: '',
  oamtStopYn: '',
  seizYn: '',
  soptTc: '',
  poaoTc: '',
  opdtNtcTc: '',
  opdtNtcZone: '',
  intPymTc: '',
  pymCyclTc: '',
  intPymDdTc: '',
  feeCalcBaseTc: '',
  bkcd: '',
  intPymAcno: '',
  basTfrt: '',
  bffeBaseC: '',
  overPrrt: '',
  bnfTfrt: '',
  mdtmPosbYn: '',
  mdtmFert01: '',
  mdtmFert02: '',
  mdtmFert03: '',
  mdtmStrtDds01: '',
  mdtmEndDds01: '',
  mdtmStrtDds02: '',
  mdtmEndDds02: '',
  mdtmStrtDds03: '',
  mdtmEndDds03: '',
  taxPrimYn: '',
  txpmTmtlYn: '',
  genTaxtTc: '',
  lastIoAmtDate: '',
  bnkbUsePageNo: '',
  bnkbUseLinNo: '',
  bnkbBdgLastTrDate: '',
  bnkbBdgLastTrno: '',
  accOpngOffrDate: '',
  tmtlNeedDds: '',
  gdC: '',
  blctIssncDate: '',
  padPrfIssncDate: '',
  ctno: '',
  contPrntNo: '',
  accOpngBrcd: '',
  contCnclDate: '',
  mngrEmno: '',
  epayTfrt: '',
  padTc: '',
  fxppTc: '',
  ftrmPymAmt: '',
  trsrRelExpl01: '',
  trsrRelExpl02: '',
  trsrLawcAgntRcno: '',
  trstPrptDnrRcno: '',
  trstMngpRcno1: '',
  trstMngpRcno2: '',
  trstMngpRcno3: '',
  epayFeeAmt: '',
  sumTamt: '',
  fxppYn: '',
  mtrtPymYn: '',
  fundStactYn: '',
  ivRskRatingC: '',
  optgYn: '',
  gdTypDtlC: '',
  brigAccYn: '',
  stopPymStrtYymm: '',
  invtMnBrcd: '',
  blktAppnIndcYn: '',
  epayPoaoTc: '',
  epayCyclTc: '',
  prfeRt: '',
  aocpRgfeYn: '',
  aocpRgfeCyclTc: '',
  aocpRgfeDate: '',
  aocpRgfeFamtTc: '',
  aocpRgfe: '',
  prfe: '',
  epayFxrtFamtTc: '',
  feeCpstAcno: '',
  feeBnkbSeq: '',
  feeAccSeq: '',
  bnfcAporRcno: '',
  dethAlmnRcno: '',
  awilEtorRcno: '',
  fnlDsgnEmno: '',
  mtrtAfTfrt: '',
  fundUnSetAmt: '',
  comsTamt: '',
  fssGdClasC: '',
  apC: '',
  sconMtr: '',
  curC: '',
  fundC: '',
  oamtDate: '',
  gdNm: '',
  tfccrhYn: ''
}

const defaultDetail03 = {
  iqryCnt: '' // 코드
}

const defaultDetail04 = {
  invtRs: '' // 코드
}

const defaultDetail06 = {
  gdC: '',
  gdNm: '',
  lastChngDt: '',
  lastChngMnTmno: '',
  lastChngMnUsid: '',
  fssGdClasC: '',
  gdIfnm: '',
  gdEnnm: '',
  trstTypC: '',
  genCumdTc: '',
  gdTypDtlC: '',
  mtrtDate: '',
  salePrmsYn: '',
  saleStrtDate: '',
  saleEndDate: '',
  saleTagtTc: '',
  genTaxtTc: '',
  poaoTc: '',
  tmtlNeedDds: '',
  intMtrtYn: '',
  fundStactYn: '',
  intPymYn: '',
  pymCyclTc: '',
  intPymDdTc: '',
  lmtAmt: '',
  amtUnit: '',
  lwstIamtAmt: '',
  histIamtAmt: '',
  lwstEtrtAmt: '',
  mtrtMinTrm: '',
  mtrtMaxTrm: '',
  tddAppnYn: '',
  aprvReqTc: '',
  addmIamtPosbYn: '',
  partTmtlPosbYn: '',
  bndlInqPosbYn: '',
  rcrtStrtYn: '',
  rcrtStrtDate: '',
  rcrtEndDate: '',
  loanPosbYn: '',
  loanPosbRto: '',
  saleAmt: '',
  aprvAmt: '',
  rsvAmt: '',
  feeCalcBaseTc: '',
  basTfrt: '',
  bffeBaseC: '',
  mdtmPosbYn: '',
  mdtmFert01: '',
  mdtmFert02: '',
  mdtmFert03: '',
  mdtmStrtDds01: '',
  mdtmEndDds01: '',
  mdtmStrtDds02: '',
  mdtmEndDds02: '',
  mdtmStrtDds03: '',
  mdtmEndDds03: '',
  regDate: '',
  sconMtr: '',
  appnTrm: '',
  appnTrmUnitC: '',
  ivcsCoNo: '',
  epayTfrt: '',
  fxppTc: '',
  ftrmPymAmt: '',
  ivRskRatingC: '',
  blktAppnIndcYn: '',
  epayPoaoTc: '',
  epayCyclTc: '',
  prfeRt: '',
  aocpRgfeYn: '',
  aocpRgfeCyclTc: '',
  aocpRgfeDd: '',
  aocpRgfeFamtTc: '',
  aocpRgfe: '',
  epayFxrtFamtTc: '',
  epayFeeAmt: '',
  dmngrAprvYn: '',
  dmngrAprvEmno: '',
  dmngrAprvDt: '',
  mtrtAfTfrt: '',
  prfe: '',
  rufeYn: '',
  apC: '',
  trstTypCNm: '',
  gdTypDtlCNm: '',
  moGdC: '',
  drvtInvtPosbYn: '',
  fcInvtPosbYn: '',
  drvtGdClasC: '',
  countDrvtGdClasC: ''
}

const defaultPayDetail = {
  bkcd: '',
  bnkAcno: '',
  bnkAccDepoNm: '',
  rltiAccSeq: '',
  bknm: '',
  acnm: '',
  payno: '',
  acnoId: '',
  regTeleTypeYn: '',
  tacno: '',
  deporRrno: ''
}

const defaultPayDetail01 = {
  rtInputChk: ''
}

const defaultTabSearch01 = {
  tacno: '',
  bnkbSeq: '',
  accSeq: '',
  pwd: '',
  msUseYn: '',
  msData: '',
  iamtProcCls: '',
  pwdYn: ''
}

const defaultDetail02 = {
  newAddCls: '',
  trAmt: '',
  aprvNo: '',
  recYn: ''
}

/* div_tacno 검색 선언부*/
const defaultdivTacnoList = {
  tacno: '',
  bnkbSeq: '',
  accSeq: '',
  accStatC: '',
  gdNm: '',
  cnm: '',
  brigAccYn: '',
  curC: '',
  contDate: '',
  mtrtDate: '',
  trstTypC: '',
  gdC: '',
  trstAmt: '',
  gdTypDtlC: '',
  mngBrcd: '',
  csId: '',
  admkNm: '',
  bnkb: '',
  acc: '',
  fundC: '',
  rcno: '',
  rcnoKindTc: '',
  soptTc: ''
}

const defaultDivTacnoSearch = {
  tacno: '',
  bnkbSeq: '',
  accStatC: '',
  brigAccYn: ''
}

// 데이터 연동이 현재 불가능하여 임시 값 설정함.
const defaultList = {
  tacno: '1101550',
  bnkbSeq: '000',
  accSeq: '000',
  accStatC: '',
  gdNm: '000 리치플랜브릿지',
  cnm: '이명희',
  brigAccYn: 'Y',
  curC: 'KRW',
  contDate: '20230322',
  mtrtDate: '99991231',
  trstTypC: '001',
  gdC: 'A1A001',
  trstAmt: '0',
  gdTypDtlC: '1',
  mngBrcd: '906',
  csId: '455342771',
  admkNm: '',
  bnkb: '',
  acc: '',
  fundC: '',
  rcno: '8803221100017',
  rcnoKindTc: '01',
  soptTc: '10'
}

const defaultTab2Search02 = {
  tacno: '',
  bnkbSeq: '',
  accSeq: '',
  aprvNo: ''
}

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPTS211M',
  screenId: 'MSPTS211M',
  components: {
    'ts-header': TSHeader,
    'ts-alert-popup': TSAlertPopup,
    'msp-ts-206p': MSPTS206P,
    'msp-ts-203p': MSPTS203P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.ds_tab1List = []
    console.log('211M Mount : ', this.$route.params)
    if (this.$route.params.tab2Tacno != null) {
      this.tabObj = this.$route.params
      console.log('211M MOUNT tab1Obj ', this.tabObj)
      this.div_tacno.tacno = this.tabObj.tab1Tacno
      //this.tabObj.tab2SearchBtnClickYn = this.$route.params.tab2SearchBtnClickYn
      this.fn_SearchValid()
    }

    //if ( this.$route.params.tacno != null  &&  this.$route.params.tacno){
    if (this.$route.params.tab1Tacno != null) {
      this.div_tacno.tacno = TSCommUtil.gfn_mask(this.$route.params.tab1Tacno)

      if (this.$route.params.tab1Tacno.length > 9) {
        this.fn_transaction()
      }
    }

    this.getStore('tsStore').dispatch('IS_CSTCSTD_STOP')

    // // 프로세스 확인
    // let t_IsProcess = this.getStore('tsStore').getters.getState.isProcess

    // // 프로세스 아닐때 단일 화면에서 초기화
    // if(!t_IsProcess){
    //   this.$bizUtil.tsUtils.saveProcTyp(this)
    // }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 로그인정보
      lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,
      lv_isProcess: this.getStore('tsStore').getters.getState.isProcess,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

      // 공통 객체
      pHeaderObj: {
        title: '실시간입금관리',
        type: 'trsf',
        step: 2
      },

      tabObj: {
        tab1Tacno: '',
        tab1Pwd: '',
        tab1SearchBtnClickYn: '',
        tab2Tacno: '',
        tab2AccSeqs: '',
        tab2BnkAccDepoNm: '',
        tab2Deposit: '',
        tab2SearchBtnClickYn: '',
        tab2SelectedIdx: '',
        tab2IsConsentConfirmBtnDisabled: false, // 확인버튼
        tab2IsConsentCancelBtnDisabled: false, // 출금해제
        tab2IsDepoReqBtnDisabled: true, //의뢰 버튼 활성화 여부
        tab2IsRdoDisabled: true, //알림톡발송 edit 여부
        tab2IsPaymAmt: true, // 이체요청금액 edit  여부
        tab2IsPwd: true, // 비밀번호 edit  여부
        tab2Rdo_smsYn: '',
        tab2Edt_paymAmt: '',
        tab2Edt_pwd: '',
        tab2AccSeq: ''
      },

      gArs_pswn_yn: 'N',

      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

      div_tacno: Object.assign({}, defaultSearch),

      ds_withDrawAgreeDetail: Object.assign({}, defaultWithDrawAgreeDetail),

      ds_tab2Search: Object.assign({}, defaultConfirm),

      ds_detail: Object.assign({}, defaultDetail),
      ds_detail02: {},
      ds_detail03: Object.assign({}, defaultDetail03),
      ds_detail04: Object.assign({}, defaultDetail04),
      ds_detail06: Object.assign({}, defaultDetail06),

      ds_payDetail: Object.assign({}, defaultPayDetail),
      ds_payDetail01: Object.assign({}, defaultPayDetail01),
      ds_tab2Search01: Object.assign({}, defaultTabSearch01),
      ds_tab2Search02: Object.assign({}, defaultTab2Search02),

      ds_divTacnoList: Object.assign({}, defaultdivTacnoList),
      ds_divTacnoSearch: Object.assign({}, defaultDivTacnoSearch),
      ds_tab1List: Object.assign({}, defaultTab1List),

      // ds_list (계좌번호 정보)
      ds_list: Object.assign({}, defaultList),

      isConsentConfirmBtnDisabled: false, //확인 버튼 활성화 여부
      isConsentCancelBtnDisabled: false, //출금계좌 선택해제 버튼 활성화 여부
      isDepoReqBtnDisabled: false, //의뢰 버튼 활성화 여부

      isRdoDisabled: true, //알림톡발송 edit 여부
      isPaymAmt: true, // 이체요청금액 edit  여부
      isPwd: true, // 비밀번호 edit  여부

      isGridClickDisabled: true, // RT 출금계좌 선택

      depositCls: [],

      mspts211mList: [],

      tacno: '',
      bnkbSeq: '',
      accSeq: '',

      bknm: '',
      bnkAcno: '',

      reqBnkAcno: '', //의뢰 버튼 클릭시 실제 넘길 계좌정보

      bnkAccDepoNm: '',
      iqryCnt: 0,

      firstWdcsYn: '',
      wdcsYn: '',
      payno: '',
      wdcsTeleYn: '',

      iamtProcCls: '',
      edt_payno: '',
      edt_paymAmt: 0,
      edt_addFee: 0,
      edt_pwd: '',

      sta_admissAmt: '',
      edt_paymAmt: '',
      edt_aprvNo: '',

      pAlertPopupObj: {},
      pPopup206Obj: {},
      pPopup203Obj: {},

      popup206: {}, // MSPTS206P
      popup203: {}, // MSPTS203P
      pPopup206Type: '',
      pPopup203Type: '',

      rdo_smsYn: '',

      valueRadio: '',

      isDone: true,

      ds_tfaacb: {}, // 계좌_계좌기본
      ds_tftcob: {}, // 계약_계약기본
      ds_tfcprb: {}, // 고객_상품기본

      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회

      lv_masked_val: '', // 마스크 변수 체크
      lv_isClear: false, // 보안키패드 초기화

      rltiTrSeqData: '', // 실시간 입금 key 값

      oldAge: '',

      ds_5186: [],
      dsListS1: {} //search 고령자 고객정보
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    rdoSmsYn() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },
    /*          
      itemSlt1() {
        let rtn = [];
        rtn.push({value: '1', text: '00리치플랜브릿지'});
        rtn.push({value: '2', text: '브라질국채(헤알화27년)2'});
        return rtn;
      },
      itemSlt2() {
        let rtn = [];
        rtn.push({value: '1', text: '입금(브릿지계좌)'});
        return rtn;
      },
      */
    // modal
    modal1() {
      return this.$refs.modal1
    },
    modal2() {
      return this.$refs.modal2
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_getNumber(val, defaultVal) {
      //mode:return value (not null).return defaultVal, (0 or null).return 0
      if (defaultVal == undefined || defaultVal == null) defaultVal = 0
      if (val == undefined || val == null || val == '') return defaultVal
      return Number(val)
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content
            this.pAlertPopupObj.contentTitle = ''
            this.pAlertPopupObj.confirm = false
            this.pAlertPopupObj.cancel = true
            this.pAlertPopupObj.type = 0

            if (!TSCommUtil.gfn_isNull(pPopupObj.confirm)) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
            } else {
              this.pAlertPopupObj.confirm = ''
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.confirmFunc)) {
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            } else {
              this.pAlertPopupObj.confirmFunc = ''
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.closeFunc)) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
            } else {
              this.pAlertPopupObj.closeFunc = ''
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.single)) {
              this.pAlertPopupObj.single = pPopupObj.single
            } else {
              this.pAlertPopupObj.single = ''
            }
          }
          break
        case 7:
        case 9:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content
            this.pAlertPopupObj.contentTitle = ''
            this.pAlertPopupObj.confirm = false
            this.pAlertPopupObj.cancel = true
            this.pAlertPopupObj.type = 0

            if (!pPopupObj.cancel) {
              this.pAlertPopupObj.cancel = pPopupObj.cancel
            }
            if (pPopupObj.confirm) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              this.pAlertPopupObj.confirmData = pPopupObj.confirmData
            }
            if (pPopupObj.closeFunc) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
              this.pAlertPopupObj.closeData = pPopupObj.closeData
            }
            if (!TSCommUtil.gfn_isNull(type) && type != 0) {
              this.pAlertPopupObj.type = type
            }
            if (pPopupObj.contentTitle) {
              this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
            }
          }
          break
      }

      this.$refs.alertPopup.fn_Open()
    },

    /*********************************************************
     * Function명: fn_OpenMSPTS206P
     * 설명: 계좌번호 조회 팝업호출
     *********************************************************/
    fn_OpenMSPTS206P(type) {
      let lv_vm = this
      this.pPopup206Type = type

      let properties = {
        pPage: 'MSPTS210M',
        //pGdC : lv_vm.gdC,              // 신탁상품
        //pTrstTypeC : lv_vm.trstTypC,  // 신탁유형
        //pGdTypDtlC : lv_vm.gdTypDtlC,  // 상품유형
        pUrl: '2000', //
        pProcTc: '2000' //
      }

      this.popup206 = this.$refs.popup206.fn_Open(properties)
    },
    /*********************************************************
     * Function명: fn_Popup206Back
     * 설명: 계좌번호 조회 팝업호출 콜백
     *********************************************************/
    fn_Popup206Back(rtnData) {
      console.log('계좌 리턴 ', rtnData)
      this.div_tacno.tacno = TSCommUtil.gfn_mask(rtnData.tacno + rtnData.bnkbSeq)
      this.div_tacno.bnkAccDepoNm = rtnData.cnm

      this.div_tacno.accSeq = rtnData.accSeq

      this.fn_transaction()

      //this.div_tacno.accSeqs.push({value: rtnData.accSeq , text: rtnData.gdNm})
      //this.div_tacno.accSeq = rtnData.accSeq
    },

    /*********************************************************
     * Function명: fn_OpenMSPTS203P
     * 설명: RT출금계좌 신규등록 팝업호출
     *********************************************************/
    fn_OpenMSPTS203P(type) {
      let lv_vm = this
      this.pPopup203Type = type

      let properties = {
        pPage: 'MSPTS211M',
        pTacno: this.div_tacno.tacno.substr(0, 7),
        pBnkbSeq: this.div_tacno.bnkbSeq,
        pAccSeq: this.div_tacno.accSeq,
        pPwd: this.edt_pwd,
        pRltiAccSeq: this.ds_payDetail.rltiAccSeq,
        pBkcd: this.ds_payDetail.bkcd,
        pBknm: this.ds_payDetail.bknm,
        pBnkAccNm: this.bnkAccDepoNm,

        pBnkAcno: this.reqBnkAcno,

        pDeporRrno: this.ds_payDetail.deporRrno,
        pTrAmt: this.edt_paymAmt,
        pTeleTypYn: 'N', // 화면에서 유선 여부
        pSsDspchYn: this.rdo_smsYn,
        pIamtType: 'NEW', // 구분 코드 값(임시 하드코딩)
        pPayno: this.edt_payno,
        pMsUseYn: '', //this.div_tacno.msUseYn -> 값없음으로 수정
        pMsData: this.div_tacno.msData,
        pAprvNo: this.edt_aprvNo, // fn_iamtCallBack 에서 받은 정보
        pStax: this.edt_addFee,
        pPwdYn: this.gArs_pswn_yn,
        pCnm: this.div_tacno.bnkAccDepoNm, // 2021-08-17 add 이체결과 보고서 출력을 위한 의뢰인 값 세팅

        pUrl: '2000', //
        pProcTc: '2000' //
      }

      console.log('211M properties ', properties)
      this.popup203 = this.$refs.popup203.fn_Open(properties)
    },

    fn_keepDepoReq() {
      //console.log('fn_keepDepoReq')
      this.depositInputProc(2)

      this.bknm = ''
      this.bnkAcno = ''
      this.reqBnkAcno = ''
      this.bnkAccDepoNm = ''
      this.rdo_smsYn = ''
      this.edt_payno = ''
      this.edt_paymAmt = ''
      this.edt_addFee = ''
      this.edt_pwd = ''
      this.ds_payDetail = Object.assign({}, defaultPayDetail)
      this.ds_withDrawAgreeDetail = Object.assign({}, defaultWithDrawAgreeDetail)
      this.acctCallBackFunc()
      this.fn_searchList()
      this.isGridClickDisabled = true // 그리드 클릭
    },

    fn_Popup203Back(rtnData) {
      //console.log('RT 입금 결과 resultYn ====> ' , rtnData.resultYn)
      //console.log('RT 입금 결과 rltiTrSeq ====> ' , rtnData.rltiTrSeq)

      //this.rltiTrSeqData = rtnData.rltiTrSeq //실시간 rltiTrSeq 셋팅

      //console.log('RT 입금 결과 ' , rtnData)
      if (rtnData == 'Y') {
        let lv_text = '같은 펀드통장에서 계속 출금처리하시겠습니까?'
        this.fn_AlertPopup(0, { content: lv_text, confirm: true, confirmFunc: this.fn_keepDepoReq, closeFunc: '' })

        this.fn_checkProcess() // 전자서식 시작
      } else {
        let lv_text = '화면 초기화 하시겠습니까?'
        this.fn_AlertPopup(0, { content: lv_text, confirm: true, confirmFunc: this.fn_init, closeFunc: '' })
      }
    },

    fn_Popup_CallBack(type, pData) {
      switch (type) {
        case 1:
          //this.fn_dataClrear()

          this.tacno = pData.tacno //계좌번호
          this.pwd = pData.pwd // 비밀번호
          this.tmtlTeleTypeYn = pData.tmtlTeleTypeYn // 유무선여부

          // 리스트 값 초기화 필요
          // 검색조건 초기화 필요
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_accSeqitemchanged
     * 설명       : 계좌 ?? 변경에 따른 데이터및 화면 재구성 로직
     ******************************************************************************/
    fn_accSeqitemchanged(selectedValue) {
      if (selectedValue != '') {
        //console.log('fn_accSeqitemchanged ' , selectedValue)
        //this.ds_tab1List = []
        this.fn_init_common()
        //console.log('fn_accSeqitemchanged ' , this.ds_list)
        let selected_info = this.ds_list.filter((item) => item.accSeq === selectedValue)[0]
        //console.log('selected_info ' , selected_info)
        //console.log('selected_info ' , selected_info.brigAccYn)
        this.div_tacno.brigAccYn = selected_info.brigAccYn
        this.edt_cnm = selected_info.cnm
        //this.div_tacno.accSeq       = this.selected_info[0].accSeq
        this.div_tacno.bnkAccDepoNm = selected_info.cnm
        this.div_tacno.trstTypC = selected_info.trstTypC
        this.div_tacno.rcnoKindTc = selected_info.rcnoKindTc
        this.div_tacno.rcno = selected_info.rcno
        this.div_tacno.bnkbSeq = selected_info.bnkbSeq
        this.div_tacno.contDate = selected_info.contDate
        this.acctCallBackFunc()
      }
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid() {
      let t_accn_no = this.div_tacno.tacno

      if (!TSCommUtil.gfn_isNull(t_accn_no)) {
        if (t_accn_no.length == 10) {
          this.div_tacno.tacno = TSCommUtil.gfn_mask(t_accn_no)
          this.fn_transaction()
        }

        this.isDepoReqBtnDisabled = true
      }
    },

    /******************************************************************************
     * Function명 : fn_transaction
     * 설명       : 계좌 정보 조회 TR 전송
     ******************************************************************************/
    fn_transaction() {
      let t_accn_no = this.div_tacno.tacno.replace(/[^0-9]/g, '').substr(0, 10)
      this.ds_divTacnoSearch.tacno = t_accn_no.substr(0, 7)
      this.ds_divTacnoSearch.bnkbSeq = t_accn_no.substr(7, 3)
      this.ds_divTacnoSearch.accStatC = ''
      this.ds_divTacnoSearch.brigAccYn = this.div_tacno.brigAccYn

      // 기존 검색 결과 초기화 함수 필요함
      // 의뢰요청함 2023-04-21
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S9' // selectTPUP95090List
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600245_S'
      this.eaiCommObj.params = {
        tacno: this.ds_divTacnoSearch.tacno,
        bnkbSeq: this.ds_divTacnoSearch.bnkbSeq,
        accStatC: '11', // 계좌상태코드(11.정상)
        brigAccYn: this.ds_divTacnoSearch.brigAccYn
      }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_transactionResult)
    },

    /******************************************************************************
     * Function명 : fn_transactionResult
     * 설명       : 계좌 정보 조회 TR 전송 후처리
     ******************************************************************************/
    fn_transactionResult(pResultData) {
      // 리턴 결과 값에 첫번째 해당 하는 정보를 히든에 넣는다.

      console.log('fn_transactionResult pResultData =======================> ', pResultData)

      if (!TSCommUtil.gfn_isNull(pResultData.data.errorMsg)) {
        this.fn_AlertPopup(0, { content: pResultData.data.errorMsg })
        return
      }

      if (pResultData.data.tpup95090.length > 0) {
        this.div_tacno.accSeqs = []
        this.ds_list = pResultData.data.tpup95090

        this.dsListS1 = {} //초기화
        this.dsListS1 = { rcno: pResultData.data.tpup95090[0].rcno } //실명번호 셋팅

        console.log('fn_transactionResult pResultData.data.tpup95090[0].rcno =======================> ', pResultData.data.tpup95090[0].rcno)

        //console.log('this.div_tacno.accSeq : ' , this.div_tacno.accSeq)

        this.ds_list.forEach((element) => {
          this.div_tacno.accSeqs.push({ value: element.accSeq, text: element.gdNm })
        })

        if (this.$route.params.accSeq != null && TSCommUtil.gfn_length(this.$route.params.accSeq) > 0) {
          this.div_tacno.accSeq = this.$route.params.accSeq
          this.fn_accSeqitemchanged(this.$route.params.accSeq)
        } else {
          //console.log('this.div_tacno.accSeq : ' , this.div_tacno.accSeq)

          if (TSCommUtil.gfn_length(this.div_tacno.accSeq) > 0) {
            let selected_info = this.ds_list.filter((item) => item.accSeq === this.div_tacno.accSeq)[0]
            this.div_tacno.brigAccYn = selected_info.brigAccYn
            this.edt_cnm = selected_info.cnm
            this.div_tacno.bnkAccDepoNm = selected_info.cnm
            this.div_tacno.trstTypC = selected_info.trstTypC
            this.div_tacno.rcnoKindTc = selected_info.rcnoKindTc
            this.div_tacno.rcno = selected_info.rcno
            this.div_tacno.bnkbSeq = selected_info.bnkbSeq
            this.div_tacno.contDate = selected_info.contDate
          } else {
            // 최초 계좌번호 조회시 강제 선택 처리
            this.div_tacno.brigAccYn = this.ds_list[0].brigAccYn
            this.edt_cnm = this.ds_list[0].cnm
            this.div_tacno.bnkAccDepoNm = this.ds_list[0].cnm
            this.div_tacno.trstTypC = this.ds_list[0].trstTypC
            this.div_tacno.rcnoKindTc = this.ds_list[0].rcnoKindTc
            this.div_tacno.rcno = this.ds_list[0].rcno
            //this.div_tacno.accSeq       = this.ds_list[0].accSeq
            this.div_tacno.bnkbSeq = this.ds_list[0].bnkbSeq
            this.div_tacno.contDate = this.ds_list[0].contDate
            this.div_tacno.accSeq = this.div_tacno.accSeqs[0].value
          }
          this.acctCallBackFunc()
        }
      }
    },

    /******************************************************************************
     * Function명 : acctCallBackFunc
     * 설명       : 계좌정보 조회
     ******************************************************************************/
    acctCallBackFunc() {
      //RT 출금계좌 선택 그리드 읽기전용 함수 필요
      // 수수료 콤보박스 읽기전용
      // 출금계좌번호 값 초기화
      // 출금계좌선택해제 버튼 안보이게
      // 확인 버튼 보이게
      // 출금계좌번호 값 초기화
      // 동의 확인 여부 값 초기화
      // 출금예정금액 초기화
      // 승인금액 초기화 sta_admissAmt
      // 수수료 값 초기화
      // 비밀번호 초기화
      // 의뢰버튼 작동불가
      // ds_.... 초기화
      //(ds_tab1List01,ds_insert01,ds_tab2Search,ds_tab2List,ds_payDetail,ds_withDrawAgreeDetail,ds_tab2Search01,ds_payDetail01, ds_tab2Search)

      let t_accn_no = this.div_tacno.tacno.replace(/[^0-9]/g, '').substr(0, 10)

      this.ds_tab2Search.tacno = t_accn_no.substr(0, 7)
      this.ds_tab2Search.bnkbSeq = t_accn_no.substr(7, 3)
      this.ds_tab2Search.accSeq = this.div_tacno.accSeq

      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS40S1' // selectTFIO40000
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600153_S'
      this.eaiCommObj.params = {
        tacno: this.ds_tab2Search.tacno,
        bnkbSeq: this.ds_tab2Search.bnkbSeq,
        accSeq: this.ds_tab2Search.accSeq
      }
      //TSServiceUtil.invoke(this.eaiCommObj, this.fn_acctCallBack)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_acctCallBack)
    },

    /******************************************************************************
     * Function명 : fn_searchList
     * 설명       : 조회
     ******************************************************************************/
    fn_searchList() {
      // 입력/선택 필수값 검증
      if (TSCommUtil.gfn_isNull(this.div_tacno.tacno)) {
        this.fn_AlertPopup(0, { content: '계좌번호를 입력하세요.' })
        return
      }

      if (TSCommUtil.gfn_isNull(this.div_tacno.accSeq)) {
        this.fn_AlertPopup(0, { content: '계좌번호를 입력하세요.' })
        return
      }

      let t_accn_no = this.div_tacno.tacno.replace(/[^0-9]/g, '').substr(0, 10)

      this.tabObj.tab2SearchBtnClickYn = 'Y'
      this.ds_tab2Search.tacno = t_accn_no.substr(0, 7)
      this.ds_tab2Search.bnkbSeq = t_accn_no.substr(7, 3)
      this.ds_tab2Search.accSeq = this.div_tacno.accSeq

      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS49S4' // selectTFIO49000AList
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600180_S'
      this.eaiCommObj.params = {
        tacno: this.ds_tab2Search.tacno,
        bnkbSeq: this.ds_tab2Search.bnkbSeq,
        accSeq: this.div_tacno.accSeq
      }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_searchResult)
    },

    /******************************************************************************
     * Function명 : fn_searchResult
     * 설명       : 조회 후처리
     ******************************************************************************/
    fn_searchResult(pResultData) {
      //this.mspts211mList = pResultData.data.tfrrai

      console.log('fn_searchResult pResultData.data.tfrrai ===================> ', pResultData.data.tfrrai)

      this.ds_tab1List = []
      this.ds_tab1List = pResultData.data.tfrrai

      //console.log('this.ds_tab1List ' , this.ds_tab1List)

      this.isDone = true

      if (this.tabObj.tab2SelectedIdx !== '') {
        this.fn_rowSelected(TSCommUtil.gfn_getNum(this.tabObj.tab2SelectedIdx))
        this.valueRadio = TSCommUtil.gfn_toString(this.tabObj.tab2SelectedIdx + 1)

        this.isConsentConfirmBtnDisabled = this.$route.params.tab2IsConsentConfirmBtnDisabled
        this.isConsentCancelBtnDisabled = this.$route.params.tab2IsConsentCancelBtnDisabled
        this.isDepoReqBtnDisabled = this.$route.params.tab2IsDepoReqBtnDisabled

        this.isRdoDisabled = this.$route.params.tab2IsRdoDisabled
        this.isPaymAmt = this.$route.params.tab2IsPaymAmt
        this.isPwd = this.$route.params.tab2IsPwd

        this.rdo_smsYn = this.$route.params.tab2Rdo_smsYn
        //this.edt_paymAmt                    = this.$route.params.tab2Edt_paymAmt
        //this.edt_pwd                        = this.$route.params.tab2Edt_pwd
      }
    },

    /************************************************************************************************
     * Function명 : dateYyMmDdFormat
     * 설명       : 날짜 형식 년월 표시 ( YYYYMMDD => YYYY-MM-DD )
     * @param {String} value : 날짜 형식 변경
     ************************************************************************************************/
    dateYyMmDdFormat(value) {
      if (!value) return ''
      value = TSCommUtil.gfn_dateReplace(1, value)
      return value
    },

    /************************************************************************************************
     * Function명 : stringBnkAcnoFormat
     * 설명       : 352*****56710
     *              023240340946
     * @param {String} value : 통장번호 형식 변경
     ************************************************************************************************/
    stringBnkAcnoFormat(value) {
      if (!value) return ''
      value = TSCommUtil.gfn_bnkAcno_mask(value)
      return value
    },

    /************************************************************************************************
     * Function명 : stringrDeporRrnoFormat
     * 설명       : 880322-1******
     * @param {String} value : 주민번호 형식 변경
     ************************************************************************************************/
    stringrDeporRrnoFormat(value) {
      if (!value) return ''
      value = TSCommUtil.gfn_jumin_mask(value)
      return value
    },

    /******************************************************************************
     * Function명 : fn_rowSelected
     * 설명       : RT 출금계좌 선택
     ******************************************************************************/
    fn_rowSelected(selectedIdx) {
      let selectedItem = this.ds_tab1List[selectedIdx]
      this.bknm = selectedItem.bknm
      this.bnkAcno = this.stringBnkAcnoFormat(selectedItem.bnkAcno)

      this.reqBnkAcno = selectedItem.bnkAcno

      this.bnkAccDepoNm = selectedItem.bnkAccDepoNm
      this.ds_payDetail.bkcd = selectedItem.bkcd
      this.ds_payDetail.bnkAcno = selectedItem.bnkAcno
      this.ds_payDetail.bnkAccDepoNm = selectedItem.bnkAccDepoNm
      this.ds_payDetail.rltiAccSeq = selectedItem.rltiAccSeq
      this.ds_payDetail.bknm = selectedItem.bknm
      this.ds_payDetail.acnm = this.div_tacno.bnkAccDepoNm
      this.ds_payDetail.deporRrno = selectedItem.deporRrno
      this.ds_payDetail.payno = selectedItem.payno
      this.ds_payDetail.acnoId = selectedItem.accId
      this.ds_payDetail.regTeleTypeYn = selectedItem.teleTypYn
      this.ds_payDetail.tacno = this.div_tacno.tacno.substr(0, 7)

      this.tabObj.tab2SelectedIdx = selectedIdx
    },

    fn_reText(c, cNm) {
      //let text = c + "" + cNm;
      //return text
    },

    fn_test() {
      this.fn_AlertPopup(0, { content: '계좌번호를 입력하세요.' })
    },
    /******************************************************************************
     * Function명 : fn_confirm
     * 설명       : 확인버튼
     ******************************************************************************/
    fn_confirm() {
      // 입력/선택 필수값 검증
      if (TSCommUtil.gfn_isNull(this.div_tacno.tacno)) {
        this.fn_AlertPopup(0, { content: '계좌번호를 입력하세요.' })
        return
      }

      if (TSCommUtil.gfn_isNull(this.bnkAcno)) {
        this.fn_AlertPopup(0, { content: '출금 계좌번호를 선택 하십시오.' })
        return
      }
      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS49S5' // selectTFIO49000G
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600182_S'

      //ds_tab2Search
      this.eaiCommObj.params = {
        tacno: this.ds_tab2Search.tacno,
        bnkbSeq: this.ds_tab2Search.bnkbSeq,
        accSeq: this.ds_tab2Search.accSeq
      }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_iqryCnkCallBack)
    },

    /******************************************************************************
     * Function명 : fn_iqryCnkCallBack
     * 설명       : 확인 후처리
     ******************************************************************************/
    fn_iqryCnkCallBack(pResultData) {
      //console.log('fn_iqryCnkCallBack ', pResultData)
      this.ds_detail03.iqryCnt = pResultData.data.iqryCnt
      let iqryCnt = pResultData.data.iqryCnt

      if (iqryCnt > 0) {
        this.fn_AlertPopup(0, { content: '동일한 은행계좌로 미처리된 내역이 존재합니다.\n[8283]RT입금이력조회 화면에서 재처리 하시기 바랍니다.' })
        return
      }

      // 안내문구 추가 confirm
      // call back
      // ok 면 프린트

      //전자금융거래법 제 15조에 의거하여, 서면 또는 녹취를 통해 고객동의를 꼭 확보해야 합니다.
      //※ 내방처리시 출금이체 신청서 수정
      //※ 유선처리시 아래 스크립트에 따라 고객동의를 확보
      //1991년 01월22일생 박진영고객님께서 삼성생명의 신탁
      //R/T를 우리은행 1002278450034본인의 계좌로 신청
      //하셨습니다. 신청 내용에 동의하십니까?

      //ds_payDetail에서 처리하자

      let birth = this.$bizUtil.getRrnToBirthDay(this.ds_payDetail.deporRrno.substr(0, 6))
      let birthYear = birth.substr(0, 4) + '년 '
      let birthMonth = birth.substr(4, 2) + '월 '
      let birthDay = birth.substr(6, 2) + '일생'

      let reBirth = birthYear + birthMonth + birthDay

      let tmpContent = []
      tmpContent.push('전자금융거래법 제15조에 의거하여, 서면 또는 녹취를 통해 고객동의를 꼭 확보해야 합니다.')
      tmpContent.push('')
      tmpContent.push('※ 내방처리시 출금이체 신청서 수령')
      tmpContent.push('※ 유선처리시 아래 스크립트에 따라 고객동의를 확보')
      tmpContent.push('')
      tmpContent.push(
        reBirth + ' ' + this.ds_payDetail.bnkAccDepoNm + '  고객님께서 삼성생명의 신탁 R/T를' + this.ds_payDetail.bknm + '은행 ' + this.ds_payDetail.bnkAcno + ' 본인의 계좌로 신청하셨습니다.'
      )
      tmpContent.push('신청 내용에 동의하십니까?')

      //this.fn_AlertPopup(t_type, t_popupObj)

      this.fn_AlertPopup(7, { content: tmpContent, confirm: true, confirmFunc: this.fn_withdrawalConsentPrint, closeFunc: this.fn_withdrawalConsentClose })
    },

    fn_withdrawalConsentPrint() {
      // 동의 클릭시
      this.fn_withdrawalConsent() //입금동의 시작
    },

    fn_withdrawalConsentClose() {
      // close(취소) 클릭시
      //this.fn_withdrawalConsent()
    },

    /******************************************************************************
     * Function명 : fn_withdrawalConsent
     * 설명       : 확인(출금동의) 후처리2
     ******************************************************************************/

    fn_withdrawalConsent() {
      // 출금동의 안내문구 추가
      // 프린트 함수 호출

      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49I3' // insertTFIO49000C
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600176_S'

      //ds_payDetail  tfio49000
      this.eaiCommObj.params = {
        bkcd: this.ds_payDetail.bkcd,
        bnkAcno: this.ds_payDetail.bnkAcno,
        bnkAccDepoNm: this.ds_payDetail.bnkAccDepoNm,
        rltiAccSeq: this.ds_payDetail.rltiAccSeq,
        bknm: this.ds_payDetail.bknm,
        acnm: this.ds_payDetail.acnm,
        payno: this.ds_payDetail.payno,
        acnoId: this.ds_payDetail.acnoId,
        regTeleTypeYn: this.ds_payDetail.regTeleTypeYn,
        tacno: this.ds_payDetail.tacno, // 7자리
        deporRrno: this.ds_payDetail.deporRrno
      }
      //console.log('params : ', this.eaiCommObj.params)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_withdrawalConsentResult)
    },

    /******************************************************************************
     * Function명 : fn_withdrawalConsent
     * 설명       : 확인 후처리3
     ******************************************************************************/

    fn_withdrawalConsentResult(pResultData) {
      //console.log('fn_withdrawalConsentResult : ', pResultData)
      let t_data = pResultData.data

      if (t_data.errorCode != 0) {
        this.fn_AlertPopup(0, { content: t_data.errorCode })
        return
      }

      if (t_data.errorMsg != 0) {
        this.fn_AlertPopup(0, { content: t_data.errorMsg })
        return
      }

      this.ds_withDrawAgreeDetail.firstWdcsYn = t_data.firstWdcsYn
      this.ds_withDrawAgreeDetail.wdcsYn = t_data.wdcsYn
      this.ds_withDrawAgreeDetail.payno = t_data.payno
      this.ds_withDrawAgreeDetail.wdcsTeleYn = t_data.wdcsTeleYn
      this.ds_withDrawAgreeDetail.firstWdcsYn = t_data.firstWdcsYn
      this.div_tacno.payno = t_data.payno
      this.div_tacno.wdcsYn = t_data.wdcsYn

      if (t_data.wdcsYn == 'Y') {
        this.depositInputProc(1) //입금처리부분 활성화
        this.isGridClickDisabled = false // 그리드 클릭

        this.isConsentConfirmBtnDisabled = true
        this.isConsentCancelBtnDisabled = true
        this.isDepoReqBtnDisabled = false

        /*
        PMPM22000016-532 아래 내용에 의거 금액이 있으면 readonly 처리함. 맞는 논리인가?
        sh4814.kim김성희 WM지원팀 신탁부 님이 댓글을 추가했습니다 - 2시간 전 - 수정됨
        개발계(EAI)에서 이체 요청 금액이 0원이며 편집이 가능한 상태인 경우는 입금대상금액이 없는 경우 입니다.
        즉, 1020화면에서 신규나 추가입금을 통해 입금 대상건을 생성하고 3030에서 승인이 완료되어 입금을 할수 있는 상태의 계좌가 되면 8282화면에 입금 대상 금액이 변경 불가능한 상태로 자동 인입됩니다.
      */
        if (!TSCommUtil.gfn_isNull(this.edt_paymAmt) && Number(this.edt_paymAmt) > 0) {
          this.isPaymAmt = true
        }
      }

      //this.fn_confirmPrintTrue(1)  //동의서 전자서식 시작
      //this.fn_checkProcess()  // 전자서식 시작
    },

    /******************************************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************************************/
    fn_checkProcess() {
      let lv_Vm = this
      let tmpContentTitle = '실시간입금완료'
      let tmpContent = []

      let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
      let basInfo = lv_Vm.getStore('tsStore').getters.getBasInfo.data
      let isLast = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)
      let t_type = 0

      console.log('fn_checkProcess basInfo.procTyp =============> ', basInfo.procTyp)

      if (isProcess) {
        if (basInfo.procTyp === 'custInfoReg') {
          tmpContent.push('송금계좌등록 화면으로 이동합니다.')
          t_type = 7
        }
        if (basInfo.procTyp === 'trsf') {
          t_type = 9
        }
      } else {
        // 프로세스 진행 중이 아닐 경우
        t_type = 9
      }

      let t_popupObj = {
        confirm: true,
        confirmFunc: this.fn_confirmPrintTrue,
        content: tmpContent,
        contentTitle: tmpContentTitle,
        confirmData: {
          tacno: this.ds_tab2Search.tacno,
          bnkbSeq: this.ds_tab2Search.bnkbSeq,
          accSeq: this.ds_tab2Search.accSeq,
          cvrTypC: this.ds_detail02.newAddCls
        }
      }
      console.log('fn_checkProcess t_popupObj =======================> ', t_popupObj)
      lv_Vm.fn_AlertPopup(t_type, t_popupObj)
    },

    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue() {
      /*
        <실시간입금화면 서식>
        -신규-
        필수: TS000001, TS000044, TS000045, TS000106, TS000060
        신탁유형: 특정금전신탁 - TS000047, TS000052
        신탁유형: 유가증권신탁 - TS000049
        신탁유형: 부동산신탁 - TS000050
        신탁유형: 유언대용신탁(금전) - TS000047, TS000048, TS000052
        신탁유형: 유언대용신탁(유가증권) - TS000049, TS000048
        신탁유형: 유언대용신탁(부동산) - TS000050, TS000048
        -추가입금- 
        필수: TS000001, TS000044, TS000045, TS000055(신탁계약 변경신청서)
      */

      this.ds_searchReportList = []

      this.ds_searchParam = {} // 초기화

      let ProcCls = '' // 신규/변경 여부

      if (this.ds_detail02.newAddCls == 'NEW') {
        ProcCls = '1'
      } else {
        ProcCls = '2'
      }

      this.ds_searchParam.tacno = this.ds_tab2Search.tacno
      this.ds_searchParam.bnkbSeq = this.ds_tab2Search.bnkbSeq
      this.ds_searchParam.ctno = Number(this.ds_tab2Search.accSeq)
      this.ds_searchParam.accSeq = this.ds_tab2Search.accSeq

      // 전자서명 동의서
      this.ds_searchReport.reportMrdNm = 'TS000001'
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      // TS000044 개인(신용)정보 등의 처리 동의 및 출금이체 신청서
      this.mappingData = {
        rltiAccSeq: this.ds_payDetail.rltiAccSeq,
        tacno: this.ds_tab2Search.tacno,
        bnkNm: this.ds_payDetail.bknm,
        bnkAcno: this.ds_payDetail.bnkAcno,
        telTyp: 'N',
        procCls: 'R',
        procDate: TSCommUtil.fn_strToday(),
        bnkbSeq: this.ds_tab2Search.bnkbSeq,
        ctno: Number(this.ds_tab2Search.accSeq),
        accSeq: this.ds_tab2Search.accSeq,
        bnkAccDepoNm: this.ds_payDetail.bnkAccDepoNm,
        btdt: this.ds_payDetail.deporRrno.substring(0, 6),
        cnm: this.ds_payDetail.bnkAccDepoNm,
        prhsRel: '본인',
        iamtProcCls: ProcCls,
        rltiTrSeq: '', //this.rltiTrSeqData                        ,
        ssDspchYn: this.rdo_smsYn,
        trAmt: this.edt_paymAmt
      }

      console.log('this.mappingData ===============> ', this.mappingData)

      this.ds_searchReport.reportMrdNm = 'TS000044' //동의서
      this.ds_searchReport.reportUrl = 'reportTFIO49000'
      this.ds_searchReport.reportParam = JSON.stringify(this.mappingData)
      this.ds_searchReport.mappingData = JSON.stringify(this.mappingData)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      //TS000045 입금 신청서
      this.ds_searchReport.reportMrdNm = 'TS000045' // 입금 신청서
      //this.ds_searchReport.reportUrl   = "reportTFIO49000A"
      this.ds_searchReport.reportUrl = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify(this.mappingData)
      this.ds_searchReport.mappingData = JSON.stringify(this.mappingData)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

      console.log('this.ds_detail02.newAddCls ====> ', this.ds_detail02.newAddCls)

      if (this.ds_detail02.newAddCls == 'NEW') {
        this.mappingData = {}

        // 신규 등록 시 200M 등록 Report 셋팅 START
        // 계좌정보 조회
        let acnoInfo = await TSInfoUtil.gfn_getAcnoInfo(this, this.ds_searchParam)
        console.log('acnoInfo : ', acnoInfo)

        let tfac20000Rst = acnoInfo.tfac20000[0]
        let tfac20000Cs = acnoInfo.tfac20000[1]

        //필수 : TS000037_8.신탁 상설핵설  교부이해확인서
        this.ds_searchReport.reportMrdNm = 'TS000037'
        this.ds_searchReport.reportUrl = 'reportTFAC20000'
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

        //상품설명서 상품코드(상품코드:gdC)로 맵핑(그 외 상품은 설명서 없음)
        if (tfac20000Rst.gdC == 'A25188') {
          // A25188 :TS000008_리치플랜하나금융지주56-2
          this.ds_searchReport.reportMrdNm = 'TS000008'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25189') {
          // A25189:TS000009_리치플랜 신한은행채권
          this.ds_searchReport.reportMrdNm = 'TS000009'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25190') {
          // A25190:TS000010_리치플랜_국고채(19-08)
          this.ds_searchReport.reportMrdNm = 'TS000010'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A41036') {
          // A41036:TS000011_리치플랜 예금
          this.ds_searchReport.reportMrdNm = 'TS000011'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A11004') {
          // A11004:TS000012_리치플랜MMT(G)
          this.ds_searchReport.reportMrdNm = 'TS000012'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25152') {
          // A25152:TS000013_마이크로소프트  회사채(2047년)
          this.ds_searchReport.reportMrdNm = 'TS000013'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25173') {
          // A25173:TS000014_마이크로소프트  회사체(2050년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000014'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25185') {
          // A25185:TS000015_버라이즌  회사채(2031년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000015'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25142') {
          // A25142:TS000016_버라이즌  회사채(2049년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000016'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25182') {
          // A25182:TS000017_아마존  회사채(2030년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000017'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25154') {
          // A25154:TS000018_아마존  회사채(2047년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000018'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25180') {
          // A25180:TS000019_애플 회사채(2031년  만기)
          this.ds_searchReport.reportMrdNm = 'TS000019'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25146') {
          // A25146:TS000020_애플 회사채(2049년  만기)
          this.ds_searchReport.reportMrdNm = 'TS000020'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25181') {
          // A25181:TS000021_인텔 회사채(2030년  만기)
          this.ds_searchReport.reportMrdNm = 'TS000021'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25153') {
          // A25153:TS000022_인텔 회사채(2049년  만기)
          this.ds_searchReport.reportMrdNm = 'TS000022'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25148') {
          // A25148:TS000023_미국채   10년(2029년 만기)
          this.ds_searchReport.reportMrdNm = 'TS000023'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25191') {
          // A25191:TS000061_리치플랜국고채(19-06)
          this.ds_searchReport.reportMrdNm = 'TS000061'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25192') {
          // A25192:TS000062_리치플랜국고채(21-09)
          this.ds_searchReport.reportMrdNm = 'TS000062'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25193') {
          // A25193:TS000063_리치플랜국고채(22-14)
          this.ds_searchReport.reportMrdNm = 'TS000063'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25197') {
          // A25197:TS000064_리치플랜국고채(16-06)
          this.ds_searchReport.reportMrdNm = 'TS000064'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25198') {
          // A25198:TS000065_리치플랜국고채(20-07)
          this.ds_searchReport.reportMrdNm = 'TS000065'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25199') {
          // A25199:TS000066_$미국국채(2024)
          this.ds_searchReport.reportMrdNm = 'TS000066'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25200') {
          // A25200:TS000067_$미국국채(2043)
          this.ds_searchReport.reportMrdNm = 'TS000067'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25201') {
          // A25201:TS000068_$미국국채(2053)
          this.ds_searchReport.reportMrdNm = 'TS000068'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45100') {
          // A45100:TS000070_KODEX 2차전지산업 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000070'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45103') {
          // A45103:TS000071_KODEX 코스닥150 ETF
          this.ds_searchReport.reportMrdNm = 'TS000071'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45102') {
          // A45102:TS000072_KODEX200 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000072'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45101') {
          // A45101:TS000073_TIGER미국테크TOP10 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000073'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25211') {
          // A25211:TS000074_리치플랜대신ELB91회
          this.ds_searchReport.reportMrdNm = 'TS000074'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45104') {
          // A45104:TS000075_T-미국P반도체 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000075'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45105') {
          // A45105:TS000076_K-종합B 액티브 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000076'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45106') {
          // A45106:TS000077_K-단기채권 PLUS ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000077'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45107') {
          // A45107:TS000078_T-미국나스닥100 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000078'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45108') {
          // A45108:TS000079_T-미국S&P500 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000079'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45109') {
          // A45109:TS000080_A-고배당주 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000080'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45110') {
          // A45110:TS000081_T-200 IT ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000081'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45111') {
          // A45111:TS000082_S-미국배당 DJ. ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000082'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25212') {
          // A25212:TS000083_리치플랜대신ELB92회
          this.ds_searchReport.reportMrdNm = 'TS000083'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45112') {
          // A45112:TS000088_T-MSCI Korea TR ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000088'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45113') {
          // A45113:TS000089_T-TOP10 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000089'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45114') {
          // A45114:TS000090_K-은행 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000090'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45115') {
          // A45115:TS000091_K-자동차 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000091'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45116') {
          // A45116:TS000092_K-반도체 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000092'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45117') {
          // A45117:TS000093_K-국고채 30년 액티브 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000093'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45118') {
          // A45118:TS000094_T-200 중공업 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000094'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45119') {
          // A45119:TS000095_T-차이나항셍테크 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000095'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45120') {
          // A45120:TS000086_K-26-12회사채A.ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000086'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45121') {
          // A45121:TS000084_T-24-10회사채(A+이상)A.ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000084'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45122') {
          // A45122:TS000085_T-25-10회사채(A+이상)A.ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000085'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45123') {
          // A45123:TS000087_H-24-09 회사채(AA-이상) A. ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000087'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25213') {
          // A25213:TS000096_리치플랜대신ELB93회
          this.ds_searchReport.reportMrdNm = 'TS000096'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25217') {
          // A25218:TS000098_리치플랜 네이버4-2
          this.ds_searchReport.reportMrdNm = 'TS000098'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25218') {
          // A25218:TS000099_리치플랜서울도철23-06
          this.ds_searchReport.reportMrdNm = 'TS000099'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // ASR240300119 신규신탁 상품설명서 전자서식 매핑 2024.03.06

        if (tfac20000Rst.gdC == 'A25219') {
          // A25219:TS000100_$미국국채 0.375_2709
          this.ds_searchReport.reportMrdNm = 'TS000100'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25220') {
          // A25220:TS000101_$미국국채 0.5_2710
          this.ds_searchReport.reportMrdNm = 'TS000101'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25221') {
          // A25221:TS000102_$미국국채 0.625_3008
          this.ds_searchReport.reportMrdNm = 'TS000102'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25222') {
          // A25222:TS000103_리치플랜 국고채(23-10)
          this.ds_searchReport.reportMrdNm = 'TS000103'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25223') {
          // A25223:TS000104_리치플랜 국고채(23-6)
          this.ds_searchReport.reportMrdNm = 'TS000104'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A25224') {
          // A25224:TS000105_리치플랜 국고채(21-2)
          this.ds_searchReport.reportMrdNm = 'TS000105'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // ASR240300879 신규신탁 상품설명서 전자서식 매핑 2024.03.25

        if (tfac20000Rst.gdC == 'A45124') {
          // A45124:TS000108_S-초단기채권 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000108'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45125') {
          // A45125:TS000109_S-반도체소부장 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000109'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45126') {
          // A45126:TS000110_A-미국채30년(H)ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000110'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45127') {
          // A45127:TS000111_K-선진국MSCI ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000111'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45128') {
          // A45128:TS000112_K-K로봇액티브 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000112'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (tfac20000Rst.gdC == 'A45129') {
          // A45129:TS000113_T-현대차그룹 ETF신탁
          this.ds_searchReport.reportMrdNm = 'TS000113'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } // 2024.08.06 ASR240701107_상품설명서 갱신에 따른 태블릿 전자서식 수정

        // 고령자여부 : thisWin.fn_lifeAge() >= thisWin.oldAge
        if (this.fn_lifeAge() >= this.oldAge) {
          // 고령자

          //상품등급 : ivRskRatingC : 1~6
          if (tfac20000Rst.ivRskRatingC == '1') {
            // 고령자/상품등급1 : TS000031_(개정_고령자1등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000031'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '2') {
            // 고령자/상품등급2 : TS000032_(개정_고령자2등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000032'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '3') {
            // 고령자/상품등급3 : TS000033_(개정_고령자3등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000033'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '4') {
            // 고령자/상품등급4 : TS000034_(개정_고령자4등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000034'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '5') {
            // 고령자/상품등급5 : TS000035_(개정_고령자5등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000035'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '6') {
            // 고령자/상품등급6 : TS000036_(개정_고령자6등급)신탁  핵심설명서_2208

            this.ds_searchReport.reportMrdNm = 'TS000036'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          }
        } else {
          // 일반

          //상품등급 : ivRskRatingC : 1~6
          if (tfac20000Rst.ivRskRatingC == '1') {
            // 일반/상품등급1 : TS000025_1등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000025'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '2') {
            // 일반/상품등급2 : TS000026_2등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000026'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '3') {
            // 일반/상품등급3 : TS000027_3등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000027'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '4') {
            // 일반/상품등급4 : TS000028_4등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000028'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '5') {
            // 일반/상품등급5 : TS000029_5등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000029'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          } else if (tfac20000Rst.ivRskRatingC == '6') {
            // 일반/상품등급6 : TS000030_6등급 신탁 핵심설명서

            this.ds_searchReport.reportMrdNm = 'TS000030'
            this.ds_searchReport.reportUrl = 'reportTFAC20000'
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
            this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
          }
        }

        // 해외상품여부 : frBondCpTc
        if (!TSCommUtil.gfn_isNull(tfac20000Rst.frBondCpTc)) {
          // 해외상품여부 Y

          // TS000038_외화채권 특정금전신탁  위험고지 확인서
          this.ds_searchReport.reportMrdNm = 'TS000038'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          // TS000024_필수_개인(신용)정보  처리동의서_(해외채권 신탁용)
          this.ds_searchReport.reportMrdNm = 'TS000024'
          this.ds_searchReport.reportUrl = ''
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }
        // 신규 등록 시 200M 등록 Report 셋팅 END

        this.ds_searchReport.reportMrdNm = ''
        this.ds_searchReport.reportUrl = 'reportTFAC20000'

        if (this.div_tacno.trstTypC == '001') {
          // 특전금전신탁

          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = 'TS000047' // TS000047_2108(삼성생명신탁)특정금전신탁 계약서 개정(시행_20210818)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          if (this.ds_tfaacb.gdTypDtlC == 'E') {
            // ISA
            this.ds_searchParam.signYn = 'N'
            this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20005.mrd'
          } else {
            this.ds_searchParam.signYn = 'N'
            this.ds_searchReport.reportMrdNm = 'TS000052' // TS000052_특정금전신탁 운용지시서
          }

          this.ds_searchParam.procType = ''
          this.ds_searchParam.signYn = 'N'
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.div_tacno.trstTypC == '008') {
          //유언대용신탁(금전)  20230425

          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = 'TS000048' // TS000048_2301(삼성생명)Whole Life신탁 특약 개정(시행_230126)

          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = 'TS000047' // TS000047_2108(삼성생명신탁)특정금전신탁 계약서 개정(시행_20210818)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.signYn = 'N'
          this.ds_searchReport.reportMrdNm = 'TS000052' // TS000052_특정금전신탁 운용지시서
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.div_tacno.trstTypC == '009') {
          //유언대용신탁(유가증권)  20230425
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = 'TS000048' // TS000048_2301(삼성생명)Whole Life신탁 특약 개정(시행_230126)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = 'TS000049' // TS000049_2301(삼성생명)유가증권신탁 계약서 개정(시행_230126)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.div_tacno.trstTypC == '010') {
          //유언대용신탁(부동산)  20230425
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = 'TS000048' //TS000048_2301(삼성생명)Whole Life신탁 특약 개정(시행_230126)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = 'TS000050' // TS000050_2301(삼성생명)을종부동산관리신탁 계약서 개정(시행_230126)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.div_tacno.trstTypC == '002') {
          //유가증권신탁
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = 'TS000049'
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.div_tacno.trstTypC == '003') {
          //재산신탁
          //arg += " report="	+ quote("RPT::R_AC2002.xml")
          this.ds_searchParam.signYn = 'N'
          this.ds_searchReport.reportMrdNm = 'TS000050'
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.div_tacno.trstTypC == '004') {
          //금전채권신탁
          //arg += " report=" + quote("RPT::R_AC2003.xml")
          this.ds_searchParam.signYn = 'N'
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20030.mrd'
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        } else if (this.div_tacno.trstTypC == '007') {
          // 2021-08-31 add 치매신탁
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20018.mrd'
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

          this.ds_searchParam.procType = '' // 아래 별지 출력 시 출력횟수 증가하지 않음
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20017.mrd' // 치매신탁별지 (운용지시서)
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (!TSCommUtil.gfn_isNull(this.ds_tfcprb.moGdC)) {
          // moGdC : 모상품코드
          this.ds_searchParam.signYn = 'Y' //직인을 사용하는 계약서 인 경우 Y
          this.ds_searchParam.moGdCYn = 'Y' //유언대용신탁 계약서
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20090.mrd'
          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        if (!TSCommUtil.gfn_isNull(this.ds_detail04.invtRs)) {
          this.ds_searchReport.reportMrdNm = '/cronix/fiduciary/contract/TFAC20050.mrd' // 적합성 보고서
          this.ds_searchReport.reportUrl = 'reportTFAC20000D'

          this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
          this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
        }

        this.ds_searchReport.reportMrdNm = 'TS000106' // 일괄체크리스트 및 확인서 ASR240700667 / [사랑on신탁] 서식_일괄체크리스트변경 2024.07.18
        this.ds_searchReport.reportUrl = 'reportTFAC20000A'
        this.ds_searchParam = {}
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

        // 투자상품판매체크리스트
        this.ds_searchReport.reportMrdNm = 'TS000060'
        this.ds_searchReport.reportUrl = ''
        this.ds_searchReport.reportParam = ''
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      } else {
        this.ds_searchParam = {}
        this.mappingData = {}

        // TS000055 신탁계약 변경신청서
        this.mappingData = {
          tacno: this.ds_tab2Search.tacno,
          bnkbSeq: this.ds_tab2Search.bnkbSeq,
          accSeq: this.ds_tab2Search.accSeq,
          ctno: Number(this.ds_tab2Search.accSeq),
          cnm: this.ds_payDetail.bnkAccDepoNm,
          teleVssTc: '1',
          aprvBusnTc1: '1031',
          addmIamt: this.edt_paymAmt
        }

        this.ds_searchReport.reportMrdNm = 'TS000055' //신탁계약변경신청서
        this.ds_searchReport.reportUrl = 'reportTFAC20000C'
        // this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        this.ds_searchReport.reportParam = JSON.stringify(this.mappingData)
        this.ds_searchReport.mappingData = JSON.stringify(this.mappingData)
        this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }

      this.fn_initReport()
      this.fn_searchReport()
    },

    /***************************************************************************************
        리포트 데이터 조회 
    *****************************************************************************************/
    fn_initReport() {
      this.ds_resultReportList = []
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        let report = { formId: this.ds_searchReportList[i].reportMrdNm }
        report.params = this.ds_searchReportList[i]
        report.mappingData = this.ds_searchReportList[i].mappingData
        this.ds_resultReportList.push(JSON.parse(JSON.stringify(report)))
      }
    },

    /***************************************************************************************
        리포트 데이터 조회 
    *****************************************************************************************/
    async fn_searchReport() {
      let isProcess = this.getStore('tsStore').getters.getState.isProcess
      let isLast = this.$bizUtil.tsUtils.getIsLastProc(this)

      console.log('fn_searchReport isProcess ======================> ', isProcess)
      console.log('fn_searchReport isLast ======================> ', isLast)
      console.log('fn_searchReport this.lv_basInfo.procTyp ======================> ', this.lv_basInfo.procTyp)

      if (isProcess) {
        if (!isLast) {
          if (this.lv_basInfo.procTyp == 'trsf') {
            //추가입금의 경우에는 실시간 입금 마지막 화면
            this.getStore('tsStore').dispatch('IS_LAST_START')
            TSInfoUtil.commReportInfo(this, this.ds_resultReportList)
          } else {
            TSInfoUtil.commReportInfo(this, this.ds_resultReportList, this.fn_movePage_MSPTS213M, false)
          }
        } else {
          TSInfoUtil.commReportInfo(this, this.ds_resultReportList)
        }
      } else {
        // 프로세스 진행 중이 아닐 경우
        TSInfoUtil.commReportInfo(this, this.ds_resultReportList)
      }
    },

    /******************************************************************************
     * Function명 : fn_movePage_MSPTS213M
     * 설명       : 송금계좌등록화면으로 이동
     ******************************************************************************/
    fn_movePage_MSPTS213M() {
      let ds_move = {}
      ds_move.tacno = this.ds_tab2Search.tacno
      ds_move.bnkbSeq = this.ds_tab2Search.bnkbSeq
      ds_move.accSeq = this.ds_tab2Search.accSeq
      console.log('fn_movePage_MSPTS213M ds_move ======================> ', ds_move)
      this.$router.push({ name: 'MSPTS213M', params: ds_move })
    },

    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      this.fn_PwdValid()
    },

    /******************************************************************************
     * Function명 : fn_init
     * 설명       : 초기화
     ******************************************************************************/

    fn_init() {
      this.tabObj.tab2Tacno = ''
      this.tabObj.tab2AccSeqs = ''
      this.tabObj.tab2BnkAccDepoNm = ''
      this.tabObj.tab2Deposit = ''
      this.tabObj.tab2SearchBtnClickYn = ''
      this.tabObj.tab2SelectedIdx = ''
      this.tabObj.tab2IsConsentConfirmBtnDisable = ''
      this.tabObj.tab2IsConsentCancelBtnDisabled = ''
      this.tabObj.tab2IsDepoReqBtnDisabled = ''
      this.tabObj.tab2IsRdoDisabled = ''
      this.tabObj.tab2IsPaymAmt = ''
      this.tabObj.tab2IsPwd = ''
      this.tabObj.tab2Rdo_smsYn = ''
      this.tabObj.tab2Edt_paymAmt = ''
      this.tabObj.tab2Edt_pwd = ''
      this.tabObj.tab2AccSeq = ''

      this.fn_init_common()
      this.div_tacno = Object.assign({}, defaultSearch)

      this.lv_isClear = !this.lv_isClear ? true : false

      this.fn_comCode() //공통코드 초기화
    },

    /******************************************************************************
     * Function명 : fn_init
     * 설명       : 초기화
     ******************************************************************************/

    fn_init_common() {
      //this.ds_tab2Search = Object.assign({}, defaultTabSearch),
      ;(this.ds_tab2Search01 = Object.assign({}, defaultTabSearch01)),
        //this.ds_tab2List = Object.assign({}, defaultTab2List),
        (this.ds_payDetail = Object.assign({}, defaultPayDetail)),
        (this.ds_withDrawAgreeDetail = Object.assign({}, defaultWithDrawAgreeDetail)),
        (this.ds_payDetail01 = Object.assign({}, defaultPayDetail01)),
        //this.tabObj.tab2SearchBtnClickYn = 'N'

        this.depositInputProc(2) //입금처리부분 활성화

      //this.ds_tab1List = Object.assign({}, defaultTab1List)
      this.ds_tab1List = null

      this.isGridClickDisabled = true
      ;(this.valueRadio = ''), (this.bknm = '')
      this.bnkAcno = ''
      this.bnkAccDepoNm = ''
      this.edt_payno = ''
      this.rdo_smsYn = ''
      this.edt_paymAmt = ''
      this.edt_addFee = ''
      this.edt_pwd = ''

      this.accSeq = ''
    },

    /******************************************************************************
     * Function명 : fn_init
     * 설명       : 초기화
     ******************************************************************************/

    fn_init_tacno_key_event() {
      let tacno = this.div_tacno.tacno

      this.fn_init_common()
      this.div_tacno = Object.assign({}, defaultSearch)

      this.div_tacno.tacno = tacno
    },

    /******************************************************************************
     * Function명 : depositInputProc
     * 설명       : 버튼제어 및 입력 박스 활성화/비활성화
     ******************************************************************************/

    depositInputProc(deposit_mode) {
      if (deposit_mode == 1) {
        //입금정보
        //알림톡발송여부 활성화
        //이체용청금액 활성화
        //비밀번호 활성화
        //의뢰버튼 활성화
        //확인(동의)버튼 비활성화
        //동의취소 활성화
        //그리드 선택기능 비활성화

        this.isConsentConfirmBtnDisabled = false //확인 버튼 활성화 여부
        this.isConsentCancelBtnDisabled = true //출금계좌 선택해제 버튼 활성화 여부
        ;(this.isDepoReqBtnDisabled = true), //의뢰 버튼 활성화 여부
          //this.tabObj.tab2IsConsentCancelBtnDisabled = true //출금계좌 선택해제 버튼 활성화 여부
          //this.tabObj.tab2IsConsentConfirmBtnDisabled = false //확인 버튼 활성화 여부
          //this.tabObj.tab2IsDepoReqBtnDisabled = true

          (this.isRdoDisabled = false)
        this.isPaymAmt = false
        this.isPwd = false
      } else {
        //알림톡발송여부 비활성화
        //이체용청금액 비활성화
        //비밀번호 비활성화
        //의뢰버튼 비활성화
        //확인(동의)버튼 활성화
        //동의취소 비활성화
        //그리드 선택기능 활성화

        this.isConsentConfirmBtnDisabled = false //확인 버튼 활성화 여부
        this.isConsentCancelBtnDisabled = false //출금계좌 선택해제 버튼 활성화 여부
        this.isDepoReqBtnDisabled = true

        //this.tabObj.tab2IsConsentCancelBtnDisabled = false //출금계좌 선택해제 버튼 활성화 여부
        //this.tabObj.tab2IsConsentConfirmBtnDisabled = true //확인 버튼 활성화 여부
        //this.tabObj.tab2IsDepoReqBtnDisabled = false

        this.isRdoDisabled = true
        this.isPaymAmt = true
        this.isPwd = true
      }
    },

    /******************************************************************************
     * Function명 : fn_withDrawAgreeCanCel
     * 설명       : 출금계좌선택해제
     ******************************************************************************/

    fn_withDrawAgreeCanCel() {
      /*
      this.ds_payDetail.clearData();
      this.ds_withDrawAgreeDetail.clearData();
      this.fn_searchList(); //RT출금계좌 조회     
      */

      // 알림톡 값 초기화
      // 이체요청금액 값 초기화
      // ds_payDetail 초기화
      // ds_withDrawAgreeDetail 초기화
      this.depositInputProc(2) //입금처리부분 비활성화

      this.tabObj = {}
      ;(this.rdo_smsYn = ''),
        //this.edt_paymAmt = '',
        (this.edt_payno = ''),
        (this.edt_wdcsYn = ''),
        (this.edt_pwd = ''),
        (this.bknm = ''),
        (this.bnkAcno = ''),
        (this.bnkAccDepoNm = ''),
        (this.ds_withDrawAgreeDetail = Object.assign({}, defaultWithDrawAgreeDetail)),
        (this.ds_payDetail = Object.assign({}, defaultPayDetail)),
        (this.isGridClickDisabled = true) // 그리드 클릭
      //this.fn_searchList()

      this.valueRadio = ''

      this.isConsentConfirmBtnDisabled = false
      this.isConsentCancelBtnDisabled = false
      this.isDepoReqBtnDisabled = true
    },

    /******************************************************************************
     * Function명 : fn_depoReq
     * 설명       : 의뢰버튼
     ******************************************************************************/

    fn_depoReq() {
      // 입력/선택 필수값 검증

      if (TSCommUtil.gfn_isNull(this.div_tacno.tacno)) {
        this.fn_AlertPopup(0, { content: '계좌번호를 입력하세요.' })
        return
      }

      if (TSCommUtil.gfn_isNull(this.div_tacno.deposit)) {
        this.fn_AlertPopup(0, { content: '입금구분을 선택하시기 바랍니다.' })
        return
      }

      if (TSCommUtil.gfn_isNull(this.div_tacno.wdcsYn)) {
        this.fn_AlertPopup(0, { content: '출금동의를 확인하시기 바랍니다.' })
        return
      }

      if (TSCommUtil.gfn_isNull(this.rdo_smsYn)) {
        this.fn_AlertPopup(0, { content: 'SMS발송여부를 선택하시기 바랍니다.' })
        return
      }

      if (TSCommUtil.gfn_isNull(this.edt_paymAmt)) {
        this.fn_AlertPopup(0, { content: '이체요청금액을 입력하시기 바랍니다.' })
        return
      }

      if (TSCommUtil.gfn_isNum(this.edt_paymAmt)) {
        if (this.edt_paymAmt < 10000) {
          this.fn_AlertPopup(0, { content: '이체요청금액은 10,000원 이상만 가능합니다.' })
          return
        }
        if (this.edt_paymAmt > 1000000000) {
          this.fn_AlertPopup(0, { content: 'RT입금 1회 이체한도는 10억입니다.' })
          return
        }
      }

      if (this.isMobile) {
        if (TSCommUtil.gfn_isNull(this.lv_masked_val)) {
          this.fn_AlertPopup(0, { content: '비밀번호 입력하시기 바랍니다.' })
          return
        }

        this.fn_PwdValid() // Mobile 비밀번호 체크 로직 추가
      } else {
        if (TSCommUtil.gfn_isNull(this.edt_pwd)) {
          this.fn_AlertPopup(0, { content: '비밀번호 입력하시기 바랍니다.' })
          return
        }

        if (TSCommUtil.gfn_length(this.edt_pwd) != 4) {
          this.fn_AlertPopup(0, { content: '비밀번호는 4자리입니다. 다시 확인하시기 바랍니다.' })
          return
        }
      }

      if (this.div_tacno.brigAccYn !== 'Y' && this.sta_admissAmt === '0') {
        this.fn_AlertPopup(0, { content: '처리가능금액이 없습니다.' })
        return
      }

      if (this.div_tacno.brigAccYn !== 'Y' && this.sta_admissAmt !== this.edt_paymAmt) {
        this.fn_AlertPopup(0, { content: '승인금액과 이체요청금액이 상이합니다.\n계약금액이 10억 초과하는경우 브릿지로 입금 후 처리하십시오.' })
        return
      }

      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S6' // selectTFIO49000D
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600181_S'

      this.ds_tab2Search01.iamtProcCls = '1'
      this.ds_tab2Search01.tacno = this.div_tacno.tacno.substr(0, 7)
      this.ds_tab2Search01.bnkbSeq = this.div_tacno.bnkbSeq
      this.ds_tab2Search01.accSeq = this.div_tacno.accSeq
      this.ds_tab2Search01.pwd = this.edt_pwd
      this.ds_tab2Search01.pwdYn = 'Y'
      this.ds_tab2Search01.msUseYn = this.div_tacno.msUseYn
      this.ds_tab2Search01.msData = this.div_tacno.msData

      /*
this.ds_tab2Search01.setColumn(nRow, "iamtProcCls", "1");
this.ds_tab2Search01.setColumn(nRow, "tacno", this.Tab00.tabpage2.form.Div02.form.div_tacno._getTacnoValue());
this.ds_tab2Search01.setColumn(nRow, "bnkbSeq", this.Tab00.tabpage2.form.Div02.form.div_tacno._getBnkbSeqValue());
this.ds_tab2Search01.setColumn(nRow, "accSeq", this.Tab00.tabpage2.form.Div02.form.div_tacno._getAccSeqValue());
this.ds_tab2Search01.setColumn(nRow, "pwd", this.Tab00.tabpage2.form.Div01.form.edt_pwd.value);
this.ds_tab2Search01.setColumn(nRow, "pwdYn", gArs_pswn_yn); //2021-08-05 add 전역변수 패스워드 체크 set (Y일 시 java단에서 패스워드 체킹 skip)
this.ds_tab2Search01.setColumn(nRow, "msUseYn", this.Tab00.tabpage2.form.Div02.form.div_tacno._getMsUseYn());
this.ds_tab2Search01.setColumn(nRow, "msData", this.Tab00.tabpage2.form.Div02.form.div_tacno._getMsData());
*/

      this.eaiCommObj.params = {
        iamtProcCls: this.ds_tab2Search01.iamtProcCls,
        tacno: this.ds_tab2Search01.tacno,
        bnkbSeq: this.ds_tab2Search01.bnkbSeq,
        accSeq: this.ds_tab2Search01.accSeq,
        pwd: this.ds_tab2Search01.pwd,
        pwdYn: this.ds_tab2Search01.pwdYn,
        msUseYn: this.ds_tab2Search01.msUseYn,
        msData: this.ds_tab2Search01.msData
      }

      //TSServiceUtil.invoke(this.eaiCommObj, this.fn_depoReqResult)
      //console.log('의뢰 버튼 클릭시 params ', this.eaiCommObj.params)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_depoReqResult)
    },

    /******************************************************************************
     * Function명 : fn_depoReqResult
     * 설명       : 의뢰버튼 후처리
     ******************************************************************************/

    fn_depoReqResult(pResultData) {
      console.log('의뢰버튼 후처리 ', pResultData)
      if (TSCommUtil.gfn_length(pResultData.data.errorMsg) != 0) {
        this.fn_AlertPopup(0, { content: pResultData.data.errorMsg })
        //return
      }

      // RT입금 팝업호출
      this.fn_OpenMSPTS203P()
    },

    /******************************************************************************
     * Function명 : fn_acctCallBack
     * 설명       :   계좌컨트롤러 호출 후 응답 (AS-IS 주석) callBack
     ******************************************************************************/

    fn_acctCallBack(pResultData) {
      // 승인번호 값 초기화 필요
      //this.Tab00.tabpage2.form.Div02.form.edt_aprvNo.set_value("");
      //this.strGdTypDtlC = this.ds_detail.getColumn(0, "gdTypDtlC");
      //this.strCsId = this.ds_detail.getColumn(0, "csId");

      //console.log('fn_acctCallBack ' , pResultData)
      this.div_tacno.depositCls = []
      this.ds_detail = pResultData.data.tfaacb
      if (pResultData.data.tfaacb != null) this.ds_detail04.invtRs = pResultData.data.tfaacb.invtRs
      this.ds_detail06 = pResultData.data.tftcob

      //console.log(' 구분 ' , this.div_tacno.trstTypC)
      if (!TSCommUtil.gfn_isNull(this.div_tacno.trstTypC)) {
        // 특정금전신탁의 경우
        if (this.div_tacno.trstTypC === '001' || this.div_tacno.trstTypC === '007' || this.div_tacno.trstTypC === '008') {
          if (this.div_tacno.brigAccYn == 'Y') {
            this.div_tacno.depositCls.push({ value: 'BRIDGE', text: '입금(브릿지계좌)' })
          } else {
            this.div_tacno.depositCls.push({ value: 'NEW', text: '신탁입금/입고' })
          }
          this.div_tacno.deposit = this.div_tacno.depositCls[0].value

          if (this.div_tacno.brigAccYn != 'Y') {
            this.fn_FeeComponentState('NEW')
          }
        }
      }

      // console.log(' 구분2 ' , this.div_tacno.depositCls)

      //if(this.tabObj.tab2SearchBtnClickYn === 'Y') this.fn_searchList()

      if (this.$route.params.tab2SearchBtnClickYn === 'Y' || this.$route.params.tacno != null) this.fn_searchList()
    },

    /******************************************************************************
     * Function명 : fn_FeeComponentState
     * 설명       :  수수료구분에 따른 콤포넌트 배치 수정 (AS-IS 주석)
     ******************************************************************************/

    fn_FeeComponentState(deposit_gubun) {
      if (deposit_gubun == 'BRIDGE') {
        if (TSCommUtil.gfn_length(this.div_tacno.tacno + this.div_tacno.accSeq) == 13) {
          this.fn_AlertPopup(0, { content: '계좌번호 입력 하지 않았습니다.' })
        }
        if (this.div_tacno.brigAccYn != 'Y') {
          this.div_tacno.deposit = this.div_tacno.depositCls[0].value
          return
        }
      } else {
        if (!TSCommUtil.gfn_isNull(this.div_tacno.tacno)) {
          /***************************************************************************************
           입금 입고 금액 조회
          *****************************************************************************************/

          //console.log('입금 입고 금액 조회')
          this.fn_iamtSearchTr() //입금 입고 승인신청금액 조회
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_iamtSearchTr
     * 설명       : 입금 입고 금액 조회
     ******************************************************************************/

    fn_iamtSearchTr() {
      let t_accn_no = this.div_tacno.tacno.replace(/[^0-9]/g, '').substr(0, 10)

      this.ds_tab2Search.tacno = t_accn_no.substr(0, 7)
      this.ds_tab2Search.bnkbSeq = t_accn_no.substr(7, 3)
      this.ds_tab2Search.accSeq = this.div_tacno.accSeq
      this.ds_tab2Search.procCls = this.div_tacno.deposit

      // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S7' // selectTFIO40000C
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600156_S'
      this.eaiCommObj.params = {
        tacno: this.ds_tab2Search.tacno,
        bnkbSeq: this.ds_tab2Search.bnkbSeq,
        accSeq: this.ds_tab2Search.accSeq,
        procCls: this.ds_tab2Search.procCls //
      }
      //console.log('입금 입고 금액 조회 ', this.eaiCommObj.params)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_iamtCallBack)
    },

    /******************************************************************************
     * Function명 : fn_iamtCallBack
     * 설명       : 입금 입고 금액 후처리
     ******************************************************************************/

    fn_iamtCallBack(pResultData) {
      console.log('입금 입고 금액 조회 결과 ', pResultData)

      // 리턴 결과가 ds_detail <- tfio40000 담긴다.
      // 그 결과를 확인해서 처리
      //ds_detail02 에 결과 값 리턴 ....
      this.ds_detail02 = _.cloneDeep(pResultData.data)

      this.sta_admissAmt = this.ds_detail02.trAmt
      this.edt_paymAmt = this.ds_detail02.trAmt
      this.edt_aprvNo = this.ds_detail02.aprvNo

      let lRecYn = this.ds_detail02.recYn

      if (!TSCommUtil.gfn_isNull(this.div_tacno.brigAccYn) && this.div_tacno.brigAccYn == 'Y') {
        this.isPaymAmt = false
      } else {
        this.isPaymAmt = true
      }

      this.fn_comCode() //공통코드 초기화

      if (lRecYn == 'Y') {
        let t_accn_no = this.div_tacno.tacno.replace(/[^0-9]/g, '').substr(0, 10)

        this.ds_tab2Search02.tacno = t_accn_no.substr(0, 7)
        this.ds_tab2Search02.bnkbSeq = t_accn_no.substr(7, 3)
        this.ds_tab2Search02.accSeq = this.div_tacno.accSeq
        this.ds_tab2Search02.aprvNo = this.ds_detail02.aprvNo

        // 기존 검색 결과 초기화 함수 필요함
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS49S10' // selectTFIO40000E
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600157_S'
        this.eaiCommObj.params = {
          tacno: this.ds_tab2Search02.tacno,
          bnkbSeq: this.ds_tab2Search02.bnkbSeq,
          accSeq: this.ds_tab2Search02.accSeq,
          aprvNo: this.ds_tab2Search02.aprvNo //
        }
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CallBackTFCCRH)
      }
    },

    /******************************************************************************
     * Function명 : fn_PwdValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_PwdValid(event) {
      let t_accn_no = this.div_tacno.tacno.replace(/[^0-9]/g, '').substr(0, 10)

      if (TSCommUtil.gfn_length(this.edt_pwd) == 4 || TSCommUtil.gfn_length(this.lv_masked_val) == 4) {
        // 기존 검색 결과 초기화 함수 필요함
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS49S8' // selectTFIO49000AList
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600247_S'
        this.eaiCommObj.params = {
          acctNo: t_accn_no,
          pwd: this.edt_pwd,
          pwdCls: 'N'
        }
        //TSServiceUtil.invoke(this.eaiCommObj, this.fn_transactionResult)

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_passwordCheckResult)
      }
    },

    /******************************************************************************
     * Function명 : fn_passwordCheckResult
     * 설명       : 비밀번호 체크 후처리
     ******************************************************************************/
    fn_passwordCheckResult(pResultData) {
      //console.log('pResultData ;' , pResultData)

      if (!TSCommUtil.gfn_isNull(pResultData.data.errorMsg)) {
        this.fn_AlertPopup(0, { content: pResultData.data.errorMsg })
        this.edt_pwd = ''
        if (this.isMobile) {
          //Mobile
          this.lv_masked_val = ''
          this.lv_isClear = false
        }
        return
      }
    },

    /******************************************************************************
     * Function명 : fn_CallBackTFCCRH
     * 설명       : 파생결합증권 녹취 여부 조회(Y) 팝업 호출
     ******************************************************************************/

    fn_CallBackTFCCRH(pResultData) {
      // 녹취 팝업 호출??????
      // ds_detail   tfio40000
      //
      /*
          var vTfccrhYn = this.gfn_trim(this.ds_detail.getColumn(0, "tfccrhYn"));
          if (this.gfn_isNull(vTfccrhYn) || vTfccrhYn < 1) 
          {
            // if( vTfccrhYn < 1 ){ //2018.05.24
            this.Tab00.tabpage2.form.Div02.form.div_tacno._fn_dataInit(); //팝업 안내 후, 계좌번호 초기화
            var varRet;
            var objParentFrame = this.getOwnerFrame();
            var newChild = new nexacro.ChildFrame;
            var oArg = {
            };

            newChild.init("TPUP97030", "absolute", "300", "300", "570", "260", null, null, "popup::TPUP97030.xfdl");
            varRet = system.showModalSync(newChild, objParentFrame, oArg, this);

            if (this.pv_popupResult.Yn == "N") 
            {
              return false;
            }
          }
          else 
          {
          }*/
    },

    /******************************************************************************
     * Function명 : fn_goPage
     * 설명       : 실시간계좌등록/해지 화면 이동
     ******************************************************************************/

    fn_goPage() {
      //let t_accn_no = this.div_tacno.tacno.replace(/[^0-9]/g, '').substr(0,10)

      //this.tabObj.tab2Tacno                       = t_accn_no //this.div_tacno.tacno
      this.tabObj.tab2Tacno = this.div_tacno.tacno

      this.tabObj.tab2AccSeqs = this.div_tacno.accSeqs
      this.tabObj.tab2BnkAccDepoNm = this.div_tacno.bnkAccDepoNm
      this.tabObj.tab2Deposit = this.div_tacno.depositCls
      this.tabObj.tab2IsConsentConfirmBtnDisabled = this.isConsentConfirmBtnDisabled
      this.tabObj.tab2IsConsentCancelBtnDisabled = this.isConsentCancelBtnDisabled
      this.tabObj.tab2IsDepoReqBtnDisabled = this.isDepoReqBtnDisabled
      this.tabObj.tab2IsRdoDisabled = this.isRdoDisabled
      this.tabObj.tab2IsPaymAmt = this.isPaymAmt
      this.tabObj.tab2IsPwd = this.isPwd

      this.tabObj.tab2Rdo_smsYn = this.rdo_smsYn
      this.tabObj.tab2Edt_paymAmt = this.edt_paymAmt
      this.tabObj.tab2Edt_pwd = this.edt_pwd

      this.tabObj.tab2AccSeq = this.div_tacno.accSeq

      //console.log('MSPTS211M-> 10M goPage', this.tabObj)

      this.$router.push({ name: 'MSPTS210M', params: this.tabObj })
    },

    /***************************************************************************************
        공통코드 호출. ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      *****************************************************************************************/
    fn_comCode() {
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {
        TUKEMK: [
          { cId: '5186' } // ds_5186    고령기준연령
        ]
      }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack, this.fn_error)
    },

    /***************************************************************************************
        공통코드 호출 CallBack
      *****************************************************************************************/
    fn_comCodeCallBack(res) {
      const resData = Object.assign({}, res.data)
      if (TSCommUtil.gfn_trim(resData.errorCode) != '') {
        if (TSCommUtil.gfn_trim(resData.errorMsg) == '') {
          this.fn_AlertPopup(0, { content: resData.errorCode })
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg })
        }
        return false
      }

      for (let index in res.data.tukemk) {
        let item = res.data.tukemk[index]
        switch (item.cid) {
          case '5186':
            this.ds_5186.push({ value: item.c, text: item.cnm })
            break
        }
      }

      //고령기준 연령 공통코드가져오기
      this.oldAge = this.ds_5186[0].text

      console.log('fn_comCodeCallBack this.oldAge ==================> ', this.oldAge)
    },

    /**********************************************************************************
     * Function명 : fn_lifeAge
     * 설명       : 고령자 체크를 위한 로직
     **********************************************************************************/
    fn_lifeAge() {
      //파생상품 위험도 팝업
      let vBirth = '' //생년월일
      let aaYear = '19'
      let rcno = this.dsListS1['rcno']
      let busyDate = this.lv_basInfo.busyDate

      rcno = rcno.replaceAll('-', '')
      let aDate = rcno.substr(0, 6) // 주민번호 앞자리만
      let sex = rcno.substr(6, 1) //성별

      //주민번호가 3이상이면 2000년도 출생
      if (sex == '3' || sex == '4' || sex == '7' || sex == '8') {
        aaYear = '20'
      }

      vBirth = aaYear + aDate
      //나이계산
      let aYear = this.fn_getNumber(vBirth.substr(0, 4))
      let aMonth = this.fn_getNumber(vBirth.substr(4, 2))
      let aDay = this.fn_getNumber(vBirth.substr(6, 2))

      let bYear = this.fn_getNumber(busyDate.substr(0, 4))
      let bMonth = this.fn_getNumber(busyDate.substr(4, 2))
      let bDay = this.fn_getNumber(busyDate.substr(6, 2))

      if (aMonth - bMonth > 0) {
        //생일 월 안지난경우
        aYear = aYear + 1
      } else if (aMonth - bMonth == 0) {
        if (aDay - bDay > 0) {
          //생일 일자 안지난경우
          aYear = aYear + 1
        }
      }
      //this.lifeAge.set_value( bYear - aYear );
      return bYear - aYear
    },

    fn_tabInit() {
      ;(this.tabObj.tab2Tacno = ''),
        (this.tabObj.tab2AccSeqs = ''),
        (this.tabObj.tab2BnkAccDepoNm = ''),
        (this.tabObj.tab2Deposit = ''),
        (this.tabObj.tab2SearchBtnClickYn = ''),
        (this.tabObj.tab2SelectedIdx = ''),
        (this.tabObj.tab2IsConsentConfirmBtnDisabled = false), // 확인버튼
        (this.tabObj.tab2IsConsentCancelBtnDisabled = false), // 출금해제
        (this.tabObj.tab2IsDepoReqBtnDisabled = false), //의뢰 버튼 활성화 여부
        (this.tabObj.tab2IsRdoDisabled = true), //알림톡발송 edit 여부
        (this.tabObj.tab2IsPaymAmt = true), // 이체요청금액 edit  여부
        (this.tabObj.tab2IsPwd = true), // 비밀번호 edit  여부
        (this.tabObj.tab2Rdo_smsYn = ''),
        (this.tabObj.tab2Edt_paymAmt = ''),
        (this.tabObj.tab2Edt_pwd = ''),
        (this.tabObj.tab2AccSeq = '')
    }
  },

  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    'div_tacno.accSeq': {
      handler(after, before) {
        //console.log('before : ', before)

        if (before != '' && after !== before) this.fn_accSeqitemchanged(after)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss"></style>

<style scoped></style>
