/*
* 업무구분: 신탁>영업>변경/해지/이체>이체>입금>실시간입금처리_P
* 화 면 명: MSPTS203P
* 화면설명: 실시간입금처리
* 작 성 일: 2023.03.24
* 작 성 자: 박은희
*/
<template>
    <!-- popup -->
    <mo-modal class="fts-modal medium" ref="modal" title="실시간입금처리">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-table-title mt-0">
            <h2 class="table-title"> 실시간이체내역확인 </h2>
          </div>
          <div class="wrap-table">
            <table class="table col-type col4060">
              <!-- <colgroup>
                <col width="180px">
                <col width="*">
              </colgroup> -->
              <tbody>
                <tr>
                  <th>
                    <span>출금계좌</span>
                  </th>
                  <td>
                    <div class="wrap-input">
                      <mo-text-field class="input-long" disabled v-model="edt_outAcno"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 예금주명 </span>
                  </th>
                  <td>
                    <div class="wrap-input">
                      <mo-text-field class="input-long" disabled v-model="edt_deporAcnm"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 입금계좌 </span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                     <mo-text-field class="input-long" disabled v-model="edt_acno"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 이체금액 </span>
                  </th>
                  <td>
                    <div class="wrap-input row">                      
                      <mo-text-field mask="number" class="input-long" disabled v-model="edt_trAmt"/>                      
                      <span> 원 </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 이체결과 </span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                     <mo-text-field class="input-long" disabled v-model="edt_result"/>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="mt-5 text-center" v-html="processMsg"><em class="colorB">전송완료</em> 정상처리 되었습니다.</p>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" v-if="isConsentConfirmBtnDisabled" @click="fn_confirm()">이체실행</mo-button>
          <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
          <!--
          <mo-button point size="large" @click="fn_Pop106()">106 호출</mo-button>
          -->
        </div>
      </template>

    <ts-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>    

    <!-- popup106 책임자 승인 -->
    <msp-ts-106p
      ref="popup106"
      @ts-popup106-callback="fn_Popup_CallBack_106"
      @ts-alert-popup="fn_AlertPopup"
      :popupObj="pPopup106Obj"
    ></msp-ts-106p>


    </mo-modal>
</template>
<script>

const defaultSearch = {
    tacno:'',
    bnkbSeq:'',
    accSeq:'',
    pwd:'',
    rltiAccSeq:'',
    bkcd:'',
    bknm:'',
    bnkAccDepoNm:'',
    bnkAcno:'',
    deporRrno:'',
    trAmt:'',
    teleTypYn:'',
    ssDspchYn:'',
    iamtType:'',
    payno:'',
    msUseYn:'',
    msData:'',
    aprvNo:'',
    mngAprvSeq:'',
    iamtProcCls:'',
    rltiAccRegMedType:'',
    bkvid:'',
    stax:'',
    pwdYn:'',
}


const defaultList = {
    bnkAcno:'',
    trBkcdNmBnkAcno:'',
}

const defaultDetail = {
    aprvNo:'',
    newRtTrsfBrkdnId:'',
    rltiTrSeq:'',
    mngAprvSeq:'',
    trDate:'',
    trno:'',
    opbel:'',
    opbelSeq:'',
    payid:'',
    errorMsg:'',
}

const defaultDetail00 = {
    bnkAccDepoNm:'',
    rcno:'',
    smsReturn:'',
    iamtProcCls:'',
}

const defaultDetail01 = {
    rltiTrSeq:'',
    lastChngDt:'',
    lastChngMnTmno:'',
    lastChngMnUsid:'',
    trDate:'',
    trDateSeq:'',
    tacno:'',
    bnkbSeq:'',
    accSeq:'',
    csId:'',
    rltiAccSeq:'',
    bkcd:'',
    bnkAcno:'',
    deporRrno:'',
    bnkAccNm:'',
    iamtReqAmt:'',
    iamtReqDt:'',
    iamtRstReflDt:'',
    rltiTrPrgStatC:'',
    rltiFtrnIstmId:'',
    ftrnRstRspnC:'',
    tcompRspnC:'',
    tcompRspnCNm:'',
    onsbObnkType:'',
    rltiProcBrcd:'',
    rltiProcEmno:'',
    teleTypeYn:'',
    ssDspchYn:'',
    delYn:'',
    trMed:'',
    iamtType:'',
    aprvNo:'',
}


const defaultSearch00 = {
    tacno:'',
    bnkbSeq:'',
    accSeq:'',
    pwd:'',
    rltiAccSeq:'',
    bkcd:'',
    bknm:'',
    bnkAccNm:'',
    bnkAcno:'',
    deporRrno:'',
    trAmt:'',
    teleTypYn:'',
    ssDspchYn:'',
    iamtType:'',
    payno:'',
    msUseYn:'',
    msData:'',
    aprvNo:'',
    newRtTrsfBrkdnId:'',
    rltiTrSeq:'',
    regTeleTypeYn:'',
    stax:'',
    iamtProcCls:'',
    bnkAccDepoNm:'',
    rltiAccRegMedType:'',
    trDate:'',
    trno:'',
    opbel:'',
    opbelSeq:'',
    payid:'',
}

const defaultPbpr = {
    pbprTp:'',
    pbprCntl:'',
    pbprPageNo:'',
    pbprLineNo:'',
    pbprOutLen:'',
    pbprData:'',
    pbprPrtCnt:'',
}

const defaultReport = {
    cNm:'',
    rcno:'',
    tacno:'',
    bnkbSeq:'',
    accSeq:'',
    bnkNm:'',
    bnkAcno:'',
    bnkAccDepoNm:'',
    telTyp:'',
    procCls:'',
    trAmt:'',
    trAmtKorean:'',
    ssDspchYn:'',
    procDate:'',
    brnm:'',
    procEnnm:'',
    phoneNum:'',
    procTime:'',
    gdNm:'',
    rltiTrSeq:'',
}

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
import MSPTS106P from "~/src/ui/ts/MSPTS106P" //책임자승인요청

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS203P",
  screenId: "MSPTS203P",
  components: {
    'ts-alert-popup': TSAlertPopup,
    "msp-ts-106p": MSPTS106P,
  },


   // 화면명
  props: {
    pPage       : String,      // 부모 페이지명
    pTacno 	 	  : String,
    pBnkbSeq 	  : String,
    pAccSeq 	  : String,
    pPwd 		    : String,
    pRltiAccSeq : String,
    pBkcd 		  : String,
    pBknm 		  : String,
    pBnkAccNm 	: String,
    pBnkAcno 	  : String,
    pDeporRrno 	: String,
    pTrAmt 		  : String,
    pTeleTypYn 	: String,
    pSsDspchYn 	: String,
    pIamtType 	: String,
    pPayno 		  : String,
    pMsUseYn 	  : String,
    pMsData 	  : String,
    pAprvNo 	  : String,
    pStax 		  : String,
    pPwdYn 		  : String,
    pCnm 		    : String, 
    pUrl        : String,
    pProcTc     : String,
    popupObj: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS203P");
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {

      lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,

      pAlertPopupObj: {},
      pPopup106Obj: {},

      trsfStatCd:'1', // 고정

      edt_outAcno:'',  //출금계좌
      edt_deporAcnm:'',  //예금주명
      edt_acno:'',  //입금계좌
      edt_trAmt:'',  //이체금액
      edt_result:'',  //이체결과
      edt_mngAprvSeq:'', //승인번호 설정???
      edt_bkvid:'', //???
      
      ds_search: Object.assign({}, defaultSearch),  
      ds_search00: Object.assign({}, defaultSearch00),  

      ds_list: Object.assign({}, defaultList),  
      ds_detail: Object.assign({}, defaultDetail),  
      ds_detail00: Object.assign({}, defaultDetail00),  
      ds_detail01: Object.assign({}, defaultDetail01),  

      ds_pbpr: Object.assign({}, defaultPbpr),  
      ds_report: Object.assign({}, defaultReport),  

      tacno: '',  
      bkcd: '',
      bnkItems: [],
      bnkList: [],

      bnkAcno: '',  //출금계좌번호
      bkAcNm: '',  //예금주명
      bnkAccDepoNm: '',  //예금주명
      rcno: '',  //주민등록번호 6자리
      
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
      validateRule: [
        (v) => !!v || '미입력된 필수 항목이 있습니다!'
      ],

      processMsg : "<em class='colorB'>전송전</em>",

      edt_mngAprvSeq : '',

      isConsentConfirmBtnDisabled: true, //확인 버튼 활성화 여부

      AprvYn : false,   //승인처리 여부

      objRtnArr : {}, // 팝업창 close시 전달할 값을 위한 배열 선언
      
   };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal() {
      return this.$refs.modal
    }
  },
   /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    // modal
    closeModal(type) {
      switch (type) {
        case 1:

          this.$emit('ts-popup-callback', 'N')
          //this.modal.close();
          break;
      }
    },
    
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : open
     ******************************************************************************/
    fn_Open(properties){
      this.fn_Clear()
      console.log('203P open properties : ', properties)
      //let edt_deporAcnm = properties.pBknm + " " + properties.pBnkAcno
      //this.edt_outAcno = properties.pBknm + " " + properties.pBnkAcno.substr(0, 3) + "*****" + properties.pBnkAcno.substr(8, properties.pBnkAcno.length)

      this.edt_outAcno = properties.pBknm + " " + properties.pBnkAcno.substr(0, 3) + "*****" + properties.pBnkAcno.substr(8, properties.pBnkAcno.length)
      this.edt_deporAcnm = properties.pBnkAccNm
      this.edt_acno = properties.pTacno +'-'+ properties.pBnkbSeq  +'-'+  properties.pAccSeq
      this.edt_trAmt = properties.pTrAmt

      this.ds_search.tacno = properties.pTacno
      this.ds_search.bnkbSeq = properties.pBnkbSeq
      this.ds_search.accSeq = properties.pAccSeq
      this.ds_search.pwd = properties.pPwd
      this.ds_search.bkcd = properties.pBkcd
      this.ds_search.bknm = properties.pBknm
      this.ds_search.bnkAccNm = properties.pBnkAccNm
      this.ds_search.bnkAcno = properties.pBnkAcno
      this.ds_search.deporRrno = properties.pDeporRrno
      this.ds_search.bnkAccDepoNm = properties.pBnkAccNm
      this.ds_search.trAmt = properties.pTrAmt
      this.ds_search.teleTypYn = properties.pTeleTypYn
      this.ds_search.ssDspchYn = properties.pSsDspchYn
      this.ds_search.pwdYn = properties.pPwdYn
      this.ds_search.iamtProcCls = properties.pIamtProcCls

      this.ds_search.payno = properties.pPayno
      this.ds_search.msUseYn = properties.pMsUseYn
      this.ds_search.msData = properties.pMsData
      this.ds_search.aprvNo = properties.pAprvNo
      this.ds_search.mngAprvSeq =  properties.pMngAprvSeq !== undefined ?  properties.pMngAprvSeq : '';      
      this.ds_search.rltiAccRegMedType = properties.pRltiAccRegMedType

      this.ds_search.rltiAccSeq = properties.pRltiAccSeq

      this.modal.open()
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close(){
      console.log('닫기')
      this.$emit('ts-popup203-callback', 'N')  // 임시 테스트 Y
      this.modal.close()
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      //this.fn_Init()
      this.ds_search = []
    },  

   fn_AlertPopup (type, pPopupObj) {
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content;

            if (!TSCommUtil.gfn_isNull(pPopupObj.confirm)) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
            } else {
              this.pAlertPopupObj.confirm = "";
            }

            if (!TSCommUtil.gfn_isNull(pPopupObj.confirmFunc)) {
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
            } else {
              this.pAlertPopupObj.confirmFunc = "";
            }

            if(pPopupObj.closeFunc != undefined){
              this.pAlertPopupObj.closeFunc = "fn_Close";
            }else{
              if (!TSCommUtil.gfn_isNull(pPopupObj.closeFunc)) {
                this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
              } else {
                this.pAlertPopupObj.closeFunc = "";
              }
            }
          }
          break;
      }

      this.$refs.alertPopup.fn_Open();
    },
 
    fn_Pop106(){  //책임자 승인 POPUP
        let fileName = ""//this.lv_basInfo.busyDate + this.lv_basInfo.brcd + this.ds_detail.mngAprvSeq + ".png";
        let properties = {

        /*
          pPage: "MSPTS213M",
          pProcDate: this.lv_basInfo.busyDate, // TSCommUtil.fn_strToday (),
          pBrcd: this.lv_basInfo.brcd, // this.getStore ("userInfo").getters.getUserInfo.brcd, p
          pMngAprvSeq: this.ds_detail.aprvNo,
          pFileName: fileName,
          pReqEmno: this.lv_basInfo.usid,
          // ------------------
          // ★★★ 기능 미구현 및 결함으로 인한 재작업(ASIS 기준), corrected by anarchi (2023.07.14)
          // pProcSeq: this.edt_amlProcNo, // (ASIS)
          // ------------------
          pProcSeq: this.ds_detail.edt_amlProcNo,
          pTacno: this.ds_acno.acno,
        */
          pPage       : 'MSPTS203P',
          pProcDate   : this.lv_basInfo.busyDate,
          pBrcd       : this.lv_basInfo.brcd,
          pMngAprvSeq : this.ds_detail.mngAprvSeq,  
          pFileName   : fileName,
          pReqEmno    : this.lv_basInfo.usid,
          pProcSeq    : '',//this.ds_detail.edt_amlProcNo,
          pTacno      : this.ds_search.tacno,//this.ds_acno.acno,
          }

        console.log('fn_Pop106 properties : ', properties)
        this.$refs.popup106.fn_Open(properties)
        return;
        
    },
    fn_Popup_CallBack_106 ( pData ) { // pData == successYn
      console.log('fn_Popup_CallBack_106 pData =========================> ',pData)
      if (!TSCommUtil.gfn_isNull(pData) && pData == "Y" ) {
        console.log('fn_Popup_CallBack_106 this.AprvYn 111 =====================>',this.AprvYn)
        this.AprvYn = true          // 승인여부 확인 값  
        console.log('fn_Popup_CallBack_106 this.AprvYn 222 =====================>',this.AprvYn)      
        console.log('승인 이후 this.ds_detail.mngAprvSeq =====================>',this.ds_detail.mngAprvSeq)  
        this.ds_search.mngAprvSeq = this.ds_detail.mngAprvSeq
        this.fn_rtTrdNewRegist()    // 승인처리 시 입금처리 다시 진행
       //this.fn_rtTrdRsltConfirm() //실시간 임급 결과확인.
      }else{
         this.ds_detail.mngAprvSeq = '' 
         this.edt_mngAprvSeq = ''
      }
    },


    /******************************************************************************
     * Function명 : fn_confirm
     * 설명       : 이체실행
     ******************************************************************************/    
    fn_confirm(){
      
      if (this.trsfStatCd == "1") {
        // 상태가 미확인경우 결과확인 할수 있음.
        this.fn_rtTrdNewRegist(); //실시간입금등록

      } else if (this.trsfStatCd == "4") {
        this.fn_rtTrdRsltConfirm(); //실시간 임급 결과확인.
      }
    },
 

   /******************************************************************************
     * Function명 : fn_rtTrdNewRegist
     * 설명       : 실시간입금등록
     ******************************************************************************/      
    fn_rtTrdNewRegist(){

        // 기존 검색 결과 초기화 함수 필요함
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS49I4' // insertTFIO49000D
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600249_S'
        this.eaiCommObj.params = {

            tacno            : this.ds_search.tacno            , 
            bnkbSeq          : this.ds_search.bnkbSeq          , 
            accSeq           : this.ds_search.accSeq           , 
            pwd              : this.ds_search.pwd              , 
            rltiAccSeq       : this.ds_search.rltiAccSeq       , 
            bkcd             : this.ds_search.bkcd             , 
            bknm             : this.ds_search.bknm             , 
            bnkAccDepoNm     : this.ds_search.bnkAccDepoNm     , 
            bnkAccDepoNm     : this.ds_search.bnkAccDepoNm     , 
            bnkAcno          : this.ds_search.bnkAcno          , 
            deporRrno        : this.ds_search.deporRrno        , 
            trAmt            : this.ds_search.trAmt            , 
            teleTypYn        : this.ds_search.teleTypYn        , 
            ssDspchYn        : this.ds_search.ssDspchYn        , 
            pwdYn            : this.ds_search.pwdYn            , 
            iamtProcCls      : '1'      , 
            //iamtProcCls      : this.ds_search.iamtProcCls      , 
            payno            : this.ds_search.payno            , 
            msUseYn          : this.ds_search.msUseYn          , 
            msData           : this.ds_search.msData           , 
            aprvNo           : this.ds_search.aprvNo           , 
            mngAprvSeq       : this.ds_search.mngAprvSeq !== undefined ?  this.ds_search.mngAprvSeq : '', 
            rltiAccRegMedType: 'I', 
            stax             : this.ds_search.stax !== undefined ?  this.ds_search.stax : '',
            bkvid            : this.ds_search.bkvid            , 
            brcd             : '906'                           ,

        }

        console.log('입금 실행 : ', this.eaiCommObj.params)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_confirmCallBack)

    },

    /******************************************************************************
     * Function명 : fn_conformCallBack
     * 설명       : 실시간입금등록 후처리
     ******************************************************************************/  
    fn_confirmCallBack(pResultData) {
      
      console.log('입금확인 버튼 클릭 : ' , pResultData)

      if ( pResultData.data.errorCode == '-1'  ) {

          console.log('입금확인 버튼 클릭 결과 오류 : ' , pResultData.data.errorCode)  
          let t_popupObj = {
            confirm: false,
            content: '이체 실행에 예기치 않은 오류가 발생되었습니다. 관리자에게 문의하세요.'
          }
          this.$emit('ts-alert-popup', 0, t_popupObj)
          return
          
          
      } else {

        // 5000만원인경우 책임자승인 요청 화면 연결해야하는 작업이 존재한다.
        this.ds_detail = pResultData.data
  
        if ( TSCommUtil.gfn_length ( this.ds_detail.errorMsg ) > 0 ) {
  
          let t_popupObj = {
            confirm: false,
            content: this.ds_detail.errorMsg
          }
          this.$emit('ts-alert-popup', 0, t_popupObj)
          return
  
        } else {
          
          console.log('정상적인 절차 진행..this.ds_detail.mngAprvSeq ~~~~~~~~~~~~~',this.ds_detail.mngAprvSeq)
          this.edt_mngAprvSeq = this.ds_detail.mngAprvSeq
          console.log('this.edt_mngAprvSeq ================> ',this.edt_mngAprvSeq)
          console.log('this.AprvYn ====================> ', this.AprvYn)
          if (this.edt_mngAprvSeq > 0) {
            if ( this.AprvYn == false ) { //책임자 승인 진행 상태 default false
              this.fn_Pop106() //책임자 승인 POPUP
            } else {
              this.trsfStatCd = '4'
              this.processMsg = "<em class='colorB'>전송중</em>* * 처리중 계속 확인바랍니다.."     
              this.edt_result = '전송중'
              this.fn_rtTrdRsltConfirm() //실시간 입금 결과확인.
            }           
          } else {
            
            this.trsfStatCd = '4'
            this.processMsg = "<em class='colorB'>전송중</em>* * 처리중 계속 확인바랍니다.."     
            this.edt_result = '전송중'
            this.fn_rtTrdRsltConfirm() //실시간 입금 결과확인.
          }
  
        }
      }
 

     },

    /******************************************************************************
     * Function명 : fn_rtTrdRsltConfirm
     * 설명       : 실시간입금 결과 확인
     ******************************************************************************/       
    fn_rtTrdRsltConfirm(){
      
    // 기존 검색 결과 초기화 함수 필요함
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49I5' // insertTFIO49000E
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600248_S'
      this.eaiCommObj.params = {

          tacno            : this.ds_search.tacno            , 
          bnkbSeq          : this.ds_search.bnkbSeq          , 
          accSeq           : this.ds_search.accSeq           , 
          pwd              : this.ds_search.pwd              , 
          rltiAccSeq       : this.ds_search.rltiAccSeq       , 
          bkcd             : this.ds_search.bkcd             , 
          bknm             : this.ds_search.bknm             , 
          bnkAccDepoNm     : this.ds_search.bnkAccDepoNm     , 
          bnkAcno          : this.ds_search.bnkAcno          , 
          deporRrno        : this.ds_search.deporRrno        , 
          trAmt            : this.ds_search.trAmt            , 
          
          regTeleTypeYn    : this.ds_search.teleTypYn        , 
          teleTypYn        : this.ds_search.teleTypYn        , 

          ssDspchYn        : this.ds_search.ssDspchYn        , 
          stax             : this.ds_search.stax             , 
          iamtProcCls      : '1',
          
          pwdYn            : this.ds_search.pwdYn            , 
          iamtProcCls      : this.ds_search.iamtProcCls      , 
          iamtType         : this.ds_search.iamtType      , 
          payno            : this.ds_search.payno            , 
          msUseYn          : this.ds_search.msUseYn          , 
          msData           : this.ds_search.msData           , 
          aprvNo           : this.ds_search.aprvNo           , 
          mngAprvSeq       : this.ds_search.mngAprvSeq       , 
          
          rltiAccRegMedType: 'I', 
          newRtTrsfBrkdnId : this.ds_detail.newRtTrsfBrkdnId,
          rltiTrSeq        : this.ds_detail.rltiTrSeq,
          trDate           : this.ds_detail.trDate,
          trno             : this.ds_detail.trno,
          opbel            : this.ds_detail.opbel,
          opbelSeq         : this.ds_detail.opbelSeq,
          payid            : this.ds_detail.payid,
 
      }

      console.log('RT결과 확인 :' , this.eaiCommObj.params)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_rtTrdRsltConfirmCallBack)

    },  

    /******************************************************************************
     * Function명 : fn_rtTrdRsltConfirm
     * 설명       : 실시간입금 결과 후처리
     ******************************************************************************/       
    fn_rtTrdRsltConfirmCallBack(pResultData) {

    console.log('RT결과 확인 콜백 :' , pResultData)

      // 리턴 메시지 확인하여 파라메터로 넘겨야함..  
      this.ds_detail = pResultData.data.tfio49000Rst
      this.ds_detail01 = pResultData.data.tfrrtl
      this.ds_pbpr = pResultData.data.passbookList
      
      if ( pResultData.data.tfrrtl.length != 0 ) {

          if (!TSCommUtil.gfn_isNull(this.ds_detail01.tcompRspnC)) {
            if (this.ds_detail01.rltiTrPrgStatC == "00") {
              // 이체결과응답코드
              this.afterTrsfProcOk(this.ds_detail01.tcompRspnCNm) //RT이체요청이 취소된경우.
            } else {
              this.afterTrsfProcFail(this.ds_detail01.tcompRspnCNm); //처리자체가 실패한경우.(이체여부 관계없음.)
            }
          } else {
            this.afterTrsfProcOk(this.ds_detail01.tcompRspnCNm); //처리자체가 성공한경우.(이체여부 관계없음.)
          }

          /*
          this.objRtnArr = {}
          this.objRtnArr.rltiTrSeq    = this.ds_detail01[0].rltiTrSeq   // rltiTrSeq 	
          this.objRtnArr.resultYn      = "Y";
          this.$emit('ts-popup203-callback', this.objRtnArr)
          */

          this.$emit('ts-popup203-callback', 'Y')
          this.modal.close()

      }

    },      

    /******************************************************************************
     * Function명 : afterTrsfProcOk
     * 설명       : RT결과확인
     ******************************************************************************/  
    afterTrsfProcOk(){

      // '02'이면 정상입금.
      if(this.ds_detail01.rltiTrPrgStatC == "02") {

        this.trsfStatCd = "2"; //정상
        this.processMsg = "<em class='colorB'>처리완료</em>",

        //확인 버튼 활성화/ 비활성화 처리 ( 비활성화 )
        //this.btn_confirm.set_enable(false);
        //this.Div01.form.edt_result.set_value(resultMsg);
        this.processMsg = "<em class='colorB'>처리완료</em> * 정상처리되었습니다."
        this.edt_result = '처리완료'
        this.isConsentConfirmBtnDisabled = false

        // 2014.05.17 KHJ SMS 연동처리
        // SMS 실패시 메시지 표시
        
        if(this.ds_detail01.ssDspchYn == '1') {
          this.processMsg = this.ds_detail00.smsReturn
        }
        // 출력 함수 필요 RT입금신청서

        //this.fn_ReportData(); //RT입금신청서

      // '03'이면 이체불능.  
      } else if(this.ds_detail01.rltiTrPrgStatC == "03") {
        this.trsfStatCd = "3" //불능
        this.processMsg = "<em class='colorB'>이체불능</em> * 처리결과 이체불능입니다."
        this.edt_result = '이체불능'
        //확인 버튼 활성화/ 비활성화 처리 ( 비활성화 )
        this.isConsentConfirmBtnDisabled = false

      } else if(this.ds_detail01.rltiTrPrgStatC == "00") {
        this.trsfStatCd = "9" //취소
        this.processMsg = "<em class='colorB'>이체취소</em> * 처리결과 이체요청실패로 취소되었습니다."
        this.edt_result = '이체취소'
        this.isConsentConfirmBtnDisabled = false
        //확인 버튼 활성화/ 비활성화 처리 ( 비활성화 )

      } else if(this.ds_detail01.rltiTrPrgStatC == "00") {
        this.trsfStatCd = "4" //미확인
        this.processMsg = "<em class='colorB'>처리중</em> * 이체진행중입니다. 결과를 다시 확인하십시오."
        this.edt_result = '처리중'
        //확인 버튼 활성화/ 비활성화 처리 ( 활성화 )     
        this.isConsentConfirmBtnDisabled = true
      }


    },

    /******************************************************************************
     * Function명 : afterTrsfProcFail
     * 설명       : RT결과확인 실패
     ******************************************************************************/  
    afterTrsfProcFail(){
      this.processMsg = "<em class='colorB'>전송전</em>* 이체요청후 결과미확인 상태입니다. \n [결과확인]버튼을 클릭해 주세요."
      this.trsfStatCd = "4";
      this.edt_result = '전송전'
      this.isConsentConfirmBtnDisabled = true
      // 확인버튼 비활성화
    },

  }

};
</script>
<style scoped>
</style>    